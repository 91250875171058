import { Slider } from "@mui/material"
import Model1 from '../Graph/Radar/model1';
import { useEffect, useState } from "react";
import './endRapport.scss'
import axios from "axios";
import { useDispatch, connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import TextComponent from "../Page/TextComponent";
import PDFRapport from "./pdf";
import PDFResum from "./pdfResum";
import Cookies from "js-cookie";
import { Puff } from "react-loader-spinner";
import Recap from "../Rapport/recap";
import allFunctions from "../Fonctions/functions.js";
import axiosFunc from "../Fonctions/axios.js";
import { rgbToHex } from "@mui/material/styles";
import Modal from "../Modal/index.js";
import ModalPin from '../Modal/pin.js'
import { setLoader } from "../../actions/action.js";

const EndRapport = ( { profilForEnd,  languageDefault, languageSelected, rapport, summaryScore, buttonTexts, paramsQuestionnaire}) => {

    const params = useParams()
    const dispatch = useDispatch()
    const [slidesValues, setSlidesValues] = useState({})
    const [editableRadar, setEditableRadar] = useState({});
    const [loadedData, setLoadedData] = useState(false)
    const [modalPDF, setModalPDF] = useState(false)
    const [modalResum, setModalResum] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [canvasDataUrl, setCanvasDataUrl] = useState(null)
    const [PDFSended, setPDFSended] = useState(false)
    const [displayModal, setDisplayModal] = useState(false)
    const [feedBack, setFeedBack] = useState()
    const token = Cookies.get('token')
    const [idAnswerForFeedBack, setIdAnswerForFeedBack] = useState()
    const [displayModalPin, setDisplayModalPin] = useState(false)
    const navigate = useNavigate()
    const [codePin, setCodePin] = useState('')
    const [textModal, setTextModal] = useState('')
    const [resumData, setResumData] = useState()
    const [colorSlide, setColorSlide] = useState()
    
    const handleCanvasDataChange = (dataURL) => {
        setCanvasDataUrl(dataURL)
    }

    console.log(rapport)

    const {questionnaire} = useParams()

      useEffect(() => {
        if(codePin.length === 4){
            axiosFunc.validatePin(codePin,dispatch, navigate, null, params)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codePin])

      
    useEffect(() => {
        if(!isLoaded && paramsQuestionnaire && paramsQuestionnaire.id){
            axiosFunc.recupRadar(params, dispatch, setColorSlide, rgbToHex, setFeedBack, setIdAnswerForFeedBack, setDisplayModal, navigate, setLoadedData, setIsLoaded, setTextModal)
        }
        if(!PDFSended && rapport && canvasDataUrl){
            // generateAndSendPDF(rapport, canvasDataUrl)
            setPDFSended(true)
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rapport, canvasDataUrl, paramsQuestionnaire])  

    const changeSlidesValues = (event, mIndex, mId) => {
        setSlidesValues(prevState => {
            const updateAnswers = {
                        value: event.target.value
                    };
                    const updateState = Object.assign({}, prevState, {
                        [mIndex]: Object.assign({}, prevState[mIndex], updateAnswers)
                      });
                    return updateState;
                  })
        setEditableRadar(prevState => {
            const updateRadar = {
                totalPoints: event.target.value
            };
            const updateState = {...prevState, [mId]: updateRadar};
            return updateState;
        })
    }

    const sendObjectives = () => {
        axios.put(`${process.env.REACT_APP_BACK_URL}/questionnaire/update_editable_chart/${questionnaire}/${params.idUser}`,
        editableRadar,
        {headers: {
            Authorization: `Bearer ${token}`,
            UserSession: Cookies.get('localToken'),
          }})
        .then((response) => {
            allFunctions.createNotification('success', 'Validation de vos objectifs', 'Objectifs enregistrés')
        })
        .catch((error) => { allFunctions.createNotification('alert', error, 'Nous avons rencontré un problème'); 
            if(error.response.status === 401){
                if(error.redirectToLogin){
                    navigate(`${process.env.REACT_APP_FRONT_LINK}/${questionnaire}`)
                } else {
                    setDisplayModalPin(true)
                }
            }
          })
    }

    const changeAnswer = ( responseId, responseText, responseValue ) => {
        axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/submit?languageSelected=${languageSelected}&languageDefault=${languageDefault}`, {
            idUser: parseInt(params.idUser),
            questionnaire: parseInt(questionnaire),
            isLast: false,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                UserSession: Cookies.get('localToken'),
              },
        },)
        .then((response) => {
            allFunctions.createNotification('success', 'Votre avis est précieux, merci de l\'avoir partagé', 'Merci pour votre avis')
            setIdAnswerForFeedBack(parseInt(responseId))
        })
        .catch((error) => {
            if(error.response.status === 401 && error.response.data.needPinCode){
                setTextModal(error.response.data.content)
                setDisplayModalPin(true)
            }
        })
    }

    const getPDFAnswers = () => {
        axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_pdf_answers_questions/${paramsQuestionnaire.id}/${params.idUser}?languageSelected=${languageSelected}&languageDefault=${languageDefault}`, {
            headers: {
                Authorization: `Bearer ${token}`,
              },
        },)
        .then((response) => {setResumData(response.data)})
    }
    return (
        paramsQuestionnaire && paramsQuestionnaire.id && isLoaded ?
        <>
        <div className={modalPDF || displayModal || displayModalPin || modalResum ? 'rapportWeb rapportWeb__noScroll' : 'rapportWeb' }>

            
            {feedBack ? <div className='questions__card questions__card__feedback'>
                <div className='questions__card__part'>
                    <div className='questions__card__text'>
                        {feedBack.asks}
                    </div>
                    <div className='questions__card__response'>
                        <div className='questions__card__button__container'>
                        {Object.entries(feedBack.options).map((option, optionIndex) => {
                            return(
                                <div className={idAnswerForFeedBack === parseInt(option[0]) ? 'questions__card__button other__choice' : 'questions__card__button'} onClick={() => {changeAnswer(option[0], option[1].text, option[1].value)}}>{option[1].text}</div>
                            )
                        })}
                        </div>
                    </div>
                </div>
            </div> : null }
            <div className='rapportWeb__button__container'>
                <div className='rapportWeb__button' onClick={() => {navigate(`${process.env.REACT_APP_FRONT_LINK}/${questionnaire}`); dispatch(setLoader(false))}}>Retour au questionnaire</div>
                <div onClick={() => {setModalPDF(true)}} className='rapportWeb__button'>Télécharger le PDF</div>
                <div onClick={() => {getPDFAnswers(); setModalResum(true)}} className='rapportWeb__button'>Télécharger le résumé Questions / Réponses</div>
            </div>
            <div className='rapportWeb__row'>
                <div className='rapportWeb__part'>
                    <div id='graphRadar' className='rapportWeb__graph'>
                        <Model1 slidesValues={slidesValues} rapport={rapport} onCanvasDataChange={handleCanvasDataChange} />
                    </div>
                </div>
                <div className='rapportWeb__part'>
                    {colorSlide ? 
                    <div className='rapportWeb__slides'>
                        <h1 className='rapportWeb__slides__title'>Modifiez vos objectifs</h1>
                        {rapport && rapport.indicator ? rapport.indicator.map((r, mIndex, key) => {
                            return(
                                <div className='rapportWeb__slide' key={mIndex}>
                                    <p>{r.nom}</p>
                                    <Slider
                                    onChange={(event) => { changeSlidesValues(event, mIndex, r.id) }}
                                    valueLabelDisplay="auto"
                                    min={0}
                                    sx={{
                                        color: colorSlide,
                                    }}
                                    className='questions__card__slide'
                                    defaultValue={rapport && rapport.series && rapport.series[0] && rapport.series[0].data && rapport.series[0].data[2] ? rapport.series[0].data[2].value[mIndex] : null}
                                    max={100} 
                                    />
                                </div>
                                )
                            })
                            : null}
                            {editableRadar && Object.entries(editableRadar).length > 0 ? <button className='home__button' onClick={() => {sendObjectives()}}>Modifiez vos objectifs</button> : <button className='home__button__close' >Modifiez vos objectifs</button>}
                    </div>
                    : summaryScore ? <Recap header={summaryScore.header} data={summaryScore.datas} totalUser={summaryScore.totalUser} totalLabelUser={summaryScore.totalUserGlobal} totalAdmin={summaryScore.totalAdmin} /> : null}
                </div>
            </div> 
            <div className='rapportWeb__row__2'>
                {profilForEnd && profilForEnd[0] ? profilForEnd.map((profil, profilIndex) => {
                    return (
                    <div key={profilIndex} className='rapportWeb__profils'>
                        <div className='rapportWeb__indicator__title'>{profil && rapport && rapport.indicator && rapport.indicator[profilIndex] ? rapport.indicator[profilIndex].nom : null}</div>
                        {profil ? <div className="questions__card rapportWeb__part__row2">
                            <div className='rapportWeb__title'>{profil && profil.title ? profil.title : null}</div>
                            <div className='rapportWeb__text'>{profil && profil.text ? <TextComponent text={profil.text} /> : null}</div>
                        </div> 
                        : null }
                    </div>  
                    )
                }) : null }
        </div>
        
        {modalPDF ? <div className='rapportWeb__modal'>
            <div className='pdf__button' onClick={() => {setModalPDF(false)}}></div>
            <PDFRapport rapport={rapport} canvasDataUrl={canvasDataUrl} /> 
        </div> : null}
        {modalResum && resumData ? <div className='rapportWeb__modal'>
            <div className='pdf__button' onClick={() => {setModalResum(false)}}></div>
            <PDFResum rapport={rapport} resumData={resumData}/> 
        </div> : null}
         
 
            { displayModalPin ?
             <ModalPin textModal={textModal} setCodePin={setCodePin}  setDisplayModalPin={setDisplayModalPin} /> 
            : null}
        </div>
        {displayModal && rapport.user.finished ? <Modal text={<>{textModal.text}<br /><br />{textModal.accept}</>} boutonOK={buttonTexts.yes} boutonStop={buttonTexts.no} setDisplayModal={setDisplayModal} action={axiosFunc.recontact} /> : null}
        </>
        : <div className="loader">
        <Puff 
            visible={true}
            color="rgb(218,91,21)"
            height=""
            width=""
            />
    </div>
    )
}

const states = (state) => ({
    rapport: state.rapport,
    languageSelected: state.languageSelected,
    languageDefault: state.languageDefault,
    idUser: state.idUser,
    summaryScore: state.summaryScore,
    profilForEnd: state.profilForEnd,
    buttonTexts: state.generiquesTextes.button,
    paramsQuestionnaire : state.paramsQuestionnaire,
  });
  
  
  export default connect(states)(EndRapport);