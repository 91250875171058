import { Card } from 'react-bootstrap'
import Question from '../Question/question'
import { connect} from "react-redux";

const CardGlobal = ( {indexLot, lot, tableDisplay, actual} ) => {

    const table = Object.entries(tableDisplay)
    
    return (
        <div key={indexLot} className='questions__card'>
            <Card.Title className='questions__card__title'>{actual && actual.page && actual.page.lots[parseInt(indexLot)] ? actual.page.lots[parseInt(indexLot)].text.title : null}</Card.Title>
            {lot && lot.length > 0 && lot.map((sousquestion, indexSousQuestion) => {
                let display = null
                table.map(([id, value]) => {
                    if(sousquestion.id === parseInt(id)) {
                        display = value
                    }} 
                )
                return (
                display ? 
                    <div key={indexSousQuestion}>
                        <div className='grid'>
                            <Question  sousquestion={sousquestion} />           
                        </div>
                    </div>
                    : null
                )
            })}
        </div>
    )
        }

const states = (state) => ({
    languageSelected: state.languageSelected,
    idMenu: state.idMenu,
    tableDisplay: state.tableDisplay,
    idPage: state.idPage,
    idQuestion: state.idQuestion, 
    menu: state.menu,
    lot: state.actual.lot,
    page: state.actual.page,
    question: state.actual.menu,
    indexLot: state.indexLot,
    actual: state.actual,
});
  


export default connect(states)(CardGlobal);