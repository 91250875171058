
import { connect, useDispatch } from "react-redux"
import { setModif } from "../../../actions/action"

const MultipleChoice = ( {sousquestion }) => {

    const dispatch = useDispatch()

    

    return (
            <div className='questions__card__button__container'>
                {Object.entries(sousquestion.options).map((option) => {
                    let isSelected = ''
                    
                    return (
                        <div className={isSelected ? 'questions__card__button other__choice' : 'questions__card__button'} onClick={() => {console.log(option[0], option[1].value, option[1].text)}}>
                            {option[1].text}
                        </div>
                    )
                })}
            </div>
    )
}

const states = (state) => ({modif: state.modif});

export default connect(states)(MultipleChoice);