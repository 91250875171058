import { Card, Button } from 'react-bootstrap'
import './home.scss'
import { connect, useDispatch} from 'react-redux'
import {useEffect, useState } from 'react'
import Modal from '../Modal'
import { PinField } from 'react-pin-field'
import { useNavigate, useParams } from 'react-router-dom'
import { Puff } from 'react-loader-spinner'
import { setLoader, setModalPin, setForgotPin, setUserMail, setReject} from '../../actions/action'
import AlertModal from '../Modal/alert'
import allFunctions from '../Fonctions/functions'
import axiosFunc from '../Fonctions/axios';
import TextComponent from '../Page/TextComponent'

const Home = ( {loader, displayChangeAnswers, modalPin, homepage, forgotPin, generiquesTextes, reject} ) => {
    
    const [codePin, setCodePin] = useState('')
    const [personalCodePin, setPersonnalCodePin] = useState('')
    const [displayAskNewPinButton, setDisplayAskNewPinButton] = useState(false)
    const [isGenerate, setIsGenerate] = useState(false)
    const navigate = useNavigate()
    const [pinError, setPinError] = useState(false)
    const dispatch = useDispatch()
    const params = useParams()
    const [displayModal, setDisplayModal] = useState(false)
    const [displayBeginButton, setDisplayBeginButton] = useState(false)

    useEffect(() => {
        if(codePin.length === 4){
            axiosFunc.validatePin(codePin,dispatch, navigate, setIsGenerate, params)
            dispatch(setLoader(true))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codePin])

   
    return (
        <div className='home'>
            {homepage && homepage.text && generiquesTextes && generiquesTextes.homePage && !loader ? 
            <div className='home__card'> 
                <Card.Title className="home__title">{homepage.title}</Card.Title>
                <div className="home__text"><TextComponent text={homepage.text} /></div>
                <Card.Text className="home__loginText">{generiquesTextes.homePage.login}</Card.Text>
                <div className='home__mail__container'>
                    <Card.Text className='home__mail__title'>{homepage.accept}</Card.Text><input className='home__mail__input' id='mailInput' onChange={(event) => {allFunctions.verifAt(event.target.value, setDisplayBeginButton); dispatch(setUserMail(event.target.value))}}></input>
                    {displayModal ? <div className='home__modal'><Modal displayModal={displayModal} setDisplayModal={setDisplayModal} action={() => axiosFunc.validChange(setDisplayModal, dispatch)}/> </div> : null}
                    {displayChangeAnswers ? <AlertModal action={() => axiosFunc.validChange(setDisplayModal, dispatch)}/> : null} 
                    {modalPin ? <div className='pin__modal'>

                    {forgotPin ? 
                                <div className='pin__container'>
                                    <div>{generiquesTextes.resetCode.generatedCode.request}</div>
                                    <input id='mailNewInput' className='home__mail__input' onChange={(event) => {allFunctions.verifAtNewAsk(event.target.value,setDisplayAskNewPinButton)}}></input>
                                    <div>
                                        {displayAskNewPinButton ? <Button className="home__button" onClick={() => {axiosFunc.askNewPin(true, navigate, setModalPin,setForgotPin,setIsGenerate,setPinError, dispatch, setReject,setDisplayAskNewPinButton, params)}}>{generiquesTextes.resetCode.page.button}</Button> : null}
                                    </div>
                                </div>
                            : null}
                    
                    {!isGenerate && !forgotPin ? 
                        <div className='pin__container'>
                            {generiquesTextes.resetCode.page.text}
                            <div className='pin__container__onlypin'>
                                <PinField 
                                    length={4}
                                    validate={/^[0-9]$/} 
                                    onChange={(event) => {setCodePin(event); }}
                                    type= {'password'}
                                    className={reject ? "pin pin__reject" : "pin"} />
                            </div>
                            {reject ? <div className='pin__error__text'>{generiquesTextes.error.failedPinCodeCheck}</div> : null}
                            <span className='pin__forgot__text'>{generiquesTextes.resetCode.generatedCode.request}</span> <span className='pin__forgot__text pin__forgot__link' onClick={() => {axiosFunc.askNewPin(false, navigate, setModalPin,setForgotPin,setIsGenerate,setPinError, dispatch, setReject,setDisplayAskNewPinButton, params)}}>{generiquesTextes.resetCode.page.link}</span>
                        </div> 
                        
                        : null} 
                        <div className='pin__closeButton' onClick={() => {dispatch(setModalPin(false)); setIsGenerate(false); dispatch(setForgotPin(false))}}></div>
                        {isGenerate ? 
                            <div className='pin__personnalisation'>
                                {generiquesTextes.resetCode.generatedCode.text} 
                                <div className='pin__container pin__container__second'>
                                <PinField
                                    length={4}
                                    validate={/^[0-9]$/} 
                                    onRejectKey={() => {dispatch(dispatch(setReject(true)))}}
                                    onChange={(event) => {dispatch(setReject(false)); setPersonnalCodePin(event)}}
                                    type= {'password'}
                                    className={reject ? "pin pin__reject" : "pin"} />
                                </div>
                                {generiquesTextes.resetCode.generatedCode.confirm}
                                <div className='pin__container pin__container__second'>
                                <PinField 
                                    length={4}
                                    validate={/^[0-9]$/} 
                                    onRejectKey={() => {dispatch(setReject(true))}}
                                    onChange={(event) => {axiosFunc.changePersonnalCodePin(event,dispatch,personalCodePin,navigate,setModalPin, params)}}
                                    type= {'password'}
                                    className={reject ? "pin pin__reject" : "pin"} />
                                    </div>
                                
                            </div>
                        : null} 
                    </div> 
                    : null}
                    {displayBeginButton ?  
                        <Button className="home__button" onClick={() => {axiosFunc.login(dispatch, navigate)}}>{generiquesTextes.button.login}</Button> 
                        : <Button className="home__button__close" ></Button> 
                    }
                </div>
            </div>
            : <div className="loader">
                    <Puff 
                        visible={true}
                        color="rgb(218,91,21)"
                        height=""
                        width=""
                        />
                </div>}
                
        </div>
    )
}

const states = (state) => ({
    allQuestions: state.allQuestions,
    idQuestionnaire: state.paramsQuestionnaire && state.paramsQuestionnaire.id ? state.paramsQuestionnaire.id : null,
    idUser: state.idUser,
    menu: state.menu,
    homepage: state.homepage,
    languageSelected: state.languageSelected,
    languageDefault: state.languageDefault,
    idCorrespond: state.idCorrespond,
    loader: state.loader,
    modalPin: state.modalPin,
    forgotPin: state.forgotPin,
    generiquesTextes : state.generiquesTextes,
    displayChangeAnswers: state.displayChangeAnswers,
    reject: state.errorPin,
});
  
  
  export default connect(states)(Home);

