/* eslint-disable react-hooks/exhaustive-deps */
import './app.scss';
import Menu from '../Menu'
import { Routes, Route, useLocation, useParams } from 'react-router-dom';
import {useEffect} from 'react'
import { connect, useDispatch } from "react-redux";
import { setPath, setLanguageSelected } from '../../actions/action';
import AppWrapper from './appWrapper';
import { BrowserView} from 'react-device-detect';
import Footer from '../footer';
import Cookies from 'js-cookie';
import axiosFunc from '../Fonctions/axios'
import allFunctions from '../Fonctions/functions'
import routes from '../../Routes'


function App({ languageSelected, languageDefault, idQuestion, modif, backgroundApp,}) {

  const location = useLocation();
  const params = useParams()
  const langueNav = navigator.language;
  const dispatch = useDispatch()

useEffect(() => {
  const myBrowser = allFunctions.detectBrowser()
    let myLanguage = 'fr-FR' // default
    switch(myBrowser) {
      case 'firefox':
        myLanguage = langueNav.toUpperCase()
        break
      default:
        myLanguage = langueNav.slice(0, 2).toUpperCase()
        break
    }
    dispatch(setLanguageSelected(myLanguage))
}, [params.questionnaire])
  

  // Dispatch l'action pour mettre à jour le chemin dans le store Redux
  useEffect(() => {
    dispatch(setPath(location.pathname));
  }, [location]);

  useEffect(() => {
    if(languageSelected && Cookies.get('token')){
      axiosFunc.getAllLanguages(languageSelected,languageDefault,dispatch);
    }
  }, [languageSelected, Cookies.get('token')]);
  

useEffect(() => {
  if (modif) { 
  const handleBeforeUnload = (event) => { 
    event.preventDefault(); 
    event.returnValue = 'Attention, vous n\'avez pas enregistré vos données.'; 
  }; 
  window.addEventListener('beforeunload', handleBeforeUnload);
   return () => { 
    window.removeEventListener('beforeunload', handleBeforeUnload);
  }}
}, [modif])


  return (      
      <div>
        <BrowserView>
        <div className="App" style={backgroundApp}>
        <div className='page__principale'>
        {idQuestion ? <Menu /> : null}
          <Routes>
              {routes.web.map((route, index) => (
                <Route 
                  key={index}
                  path={route.path}
                  exact
                  element={<> <AppWrapper /><route.component /> </> }
                />
              ))}
            </Routes>
            </div>
          <Footer />
      </div>
        </BrowserView>          
      </div>
    );}

const states = (state) => ({
  languageSelected: state.languageSelected,
  languageDefault: state.languageDefault,
  modif: state.modif,
  idQuestion: state.idQuestion,
  backgroundApp: state.backgroundApp,
});
  
export default connect(states)(App);