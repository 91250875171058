import './menu.scss';
import { Link, useLocation} from 'react-router-dom'
import {  useEffect } from 'react'
import { connect, useDispatch } from "react-redux";
import { setMenuSelect, setStep, setDisplayUnderMenu, setActual, setLoader, setModalPin, } from '../../actions/action.js'
import allFunctions from '../Fonctions/functions.js';
import axiosFunc from '../Fonctions/axios.js';
import { BsArrowLeft } from "react-icons/bs";

const Menu = ( {idUser, paramsQuestionnaire, menuContent, displayUnderMenu, needUnderMenu, actual, underMenu, idMenu, allQuestions} ) => {
  const dispatch = useDispatch();
  const location = useLocation()
  
  const TextWithEllipsis = (text, maxLength) => {
    if (text && text.length <= maxLength) {
      return <span>{text}</span>;
    } else if (text) {
      const truncatedText = text.slice(0, maxLength - 3) + '...';
      return <span>{truncatedText}</span>;
    }
  }

  useEffect(() => {
    if(needUnderMenu){
      allFunctions.getUnderMenu(dispatch)
    }
  }, [actual.menu])

  useEffect(() => {
    axiosFunc.getMenu(dispatch)
    axiosFunc.getMenu(dispatch, idUser)
  }, [])

    return (
      <div className="menu">
        <div className="menu__ul">
          <Link className='menu__logo__container' to={`${process.env.REACT_APP_FRONT_LINK}/${paramsQuestionnaire.idUrl ? paramsQuestionnaire.idUrl : paramsQuestionnaire.id}`} onClick={()=> {dispatch(setLoader(false), dispatch(setModalPin(false)))}}>
            <img className='menu__logo' src={paramsQuestionnaire && paramsQuestionnaire.logo && paramsQuestionnaire.logo.url} alt='logo' />
          </Link>
          {needUnderMenu && displayUnderMenu ? 
          <>
          {underMenu && underMenu[0] && underMenu.map((m, mIndex) => {
            return(
                  <Link key={mIndex} 
                  className={actual && actual.page && actual.page.text && parseInt(m.text.id) === parseInt(actual.page.text.id) ? "menu__ul__li menu__ul__li__undermenu selected" : "menu__ul__li menu__ul__li__undermenu"}
                  to={`${process.env.REACT_APP_FRONT_LINK}/${paramsQuestionnaire.idUrl ? paramsQuestionnaire.idUrl : paramsQuestionnaire.id}/${idUser}/${idMenu}/${m.text.id}/0/${m.lots[0].sousquestions[0].id}`}
                  onClick={() => {dispatch(setActual(mIndex, 'pageIndex'))}}>
                    <div title={m.text.title}>{mIndex+1} - {TextWithEllipsis(m.text.title, '35')}</div>
                  </Link>
                  )})}
                  {needUnderMenu && displayUnderMenu ? <div className='menu__return' onClick={() => {dispatch(setDisplayUnderMenu(false))}}><BsArrowLeft /> Retour</div> : null}
                  </>
          : 
        menuContent && menuContent[0] ?
        <>
        {menuContent.map((m, mIndex) => {
            return(
                  <Link key={mIndex} 
                  className={m.title === actual.menuName ? "menu__ul__li selected" : "menu__ul__li"} 
                  to={`${process.env.REACT_APP_FRONT_LINK}/${paramsQuestionnaire.idUrl ? paramsQuestionnaire.idUrl : paramsQuestionnaire.id}/${idUser}/${m.id}/${allQuestions[m.id].pages[0].text.id}/0/${allFunctions.findFirstPage(m.id).pages[0].lots[0].sousquestions[0].id}`}
                  onClick={() => {dispatch(setDisplayUnderMenu(true)); dispatch(setStep(0))}}>
                    <div>{m.title}</div>
                  </Link>
                  )
                  })}
                   <Link to={`${process.env.REACT_APP_FRONT_LINK}/rapport/${paramsQuestionnaire.idUrl ? paramsQuestionnaire.idUrl : paramsQuestionnaire.id}/${idUser}`} className={location.pathname.includes('rapport') ? "menu__ul__li selected" : "menu__ul__li"}>Rapport</Link>
        <Link to={`${process.env.REACT_APP_FRONT_LINK}/menu/${paramsQuestionnaire.idUrl ? paramsQuestionnaire.idUrl : paramsQuestionnaire.id}/${idUser}`} className={location.pathname.includes('menu') ? "menu__ul__li selected" : "menu__ul__li"}> Modifier vos choix de thèmes </Link>

             </> : null}
             </div>
          </div>
    );
  };
  // {m.text.title === actual.menu.category.title ? "menu__ul__li selected" :
  const mapStateToProps = (state) => ({
    idUser: state.idUser,
    paramsQuestionnaire: state.paramsQuestionnaire,
    displayUnderMenu: state.displayUnderMenu,
    needUnderMenu: state.paramsQuestionnaire.needSousMenu,
    displayReportMenu: state.displayReportMenu,
    themeSelectionActive: state.themeSelectionActive,
    underMenu : state.underMenu,
    actual: state.actual,
    menuContent: state.userMenu,
    idMenu : state.idMenu,
    allQuestions: state.allQuestions,
  });
  const mapDispatchToProps = dispatch => ({
    setMenuSelect: (payload) => dispatch(setMenuSelect(payload)),
    setStep: (payload) => dispatch(setStep(payload)),
  });
  


export default connect(mapStateToProps, mapDispatchToProps)(Menu);
