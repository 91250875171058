import Home from "./components/Home/index";
import Page from "./components/Page/index";
import EndRapport from "./components/EndRapport";
import Rapport from "./components/Rapport";
import MenuChoice from "./components/MenuChoice";

const routes = {
    web: [
        // PAGE DE CONNEXION
        { path: `${process.env.REACT_APP_FRONT_LINK}/:questionnaire`, component: Home},

        // PAGE DE CHOIX DU MENU
        { path: `${process.env.REACT_APP_FRONT_LINK}/menu/:questionnaire/:idUser`, component: MenuChoice},

        // PAGE DU QUESTIONNAIRE
        { path: `${process.env.REACT_APP_FRONT_LINK}/:questionnaire/:idUser/:menuId/:idPage/:indexLot/:idQuestion`, component: Page},

        // PAGE DU RAPPORT DE FIN DE CHAPITRE
        { path: `${process.env.REACT_APP_FRONT_LINK}/:questionnaire/:idUser/:menuId/:idPage/:indexLot/:idQuestion/profil`, component: Rapport},

        // PAGE DE RAPPORT FINAL
        { path: `${process.env.REACT_APP_FRONT_LINK}/rapport/:questionnaire/:idUser`, component: EndRapport},
    ]
}

export default routes