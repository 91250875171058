import axios from 'axios'
import Cookies from 'js-cookie'
import { setAllQuestions, setAllQuestionsAnswers, setMenu, setLanguages, setHomepage, setLoader,setToken,setProfilForEnd,setSummaryScore, setRapport, setAlertContainer, setModalPin, setGeneriquesTextes, setParamsQuestionnaire, setUserMenu, setIdUser, setModif, setDisplayChangeAnswers, setReject } from '../../actions/action'
import allFunctions from './functions';
import store from '../../store/index'; 


const axiosFunc = {
  //***************************RECUPERATION DE DATA *************************** //
  // Récupère l'intégralité des questions et du menu et les enregistre dans le Reducer
  getQuestions: async (navigate, dispatch) => {

    const state = store.getState()
    return new Promise(async (resolve, reject) => {
       try {
      let response = await axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_survey/${state.paramsQuestionnaire.id}`, {
        headers: {UserSession: Cookies.get('localToken'),Authorization: `Bearer ${Cookies.get('token')}`},      
        params: { languageSelected: state.languageSelected, languageDefault: state.paramsQuestionnaire.group.lang}
      })
      if(response.data.length !== 0) {
        dispatch(setAllQuestions(response.data.content.questions));
          for ( const [idTheme, theme] of Object.entries(response.data.content.questions)) {
            theme.pages.map(page => {
              page.lots.map((lot, indexLot) => {
                lot.sousquestions.map(question => {
                  allFunctions.recursiveAnswers(dispatch, idTheme, page.text.id, indexLot, question.id)
                })
              })
            
            });
            }
        // allFunctions.createTableQuestion(response.data.content.questions)
      }
      resolve(response.data.content.questions)
      return response.data.content.questions
    } catch (error) {
      reject(error)
      if (error.response && error.response.redirectToLogin && navigate){
        navigate(`/${process.env.REACT_APP_FRONT_LINK}/${state.paramsQuestionnaire.idUrl ? state.paramsQuestionnaire.idUrl : state.paramsQuestionnaire.id}`)
      }
    }
    })
   
  },

  getPersonnalQuestions: async (navigate, dispatch) => {
    const state = store.getState()
    return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_survey_user/${state.paramsQuestionnaire.id}/${state.idUser}`, {
        headers: {UserSession: Cookies.get('localToken'),Authorization: `Bearer ${Cookies.get('token')}`},      
        params: { languageSelected: state.languageSelected, languageDefault: state.paramsQuestionnaire.group.lang}
      })
      if(response.data.length !== 0) {
        dispatch(setAllQuestions(response.data.content.questions));
          for ( const [idTheme, theme] of Object.entries(response.data.content.questions)) {
            theme.pages.map(page => {
              page.lots.map((lot, indexLot) => {
                lot.sousquestions.map(question => {
                  allFunctions.recursiveAnswers(dispatch, idTheme, page.text.id, indexLot, question.id)
                })
              })
            
            });
            }
      }
      resolve(response.data.content.questions)
      return response.data.content.questions
    } catch (error) {
      reject(error)
      if (error.response && error.response.redirectToLogin && navigate){
        navigate(`${process.env.REACT_APP_FRONT_LINK}/${state.paramsQuestionnaire.idUrl ? state.paramsQuestionnaire.idUrl : state.paramsQuestionnaire.id}`)
      }
    }
    })
  },

//***************************CONNEXION************************************ //
  // Connection au site
  login : async (dispatch, navigate, validatePin, isNotRefresh) => {
    const state = store.getState()
    const mailValue = state.userMail
    let headers = {Authorization: `Bearer ${Cookies.get('token')}`}
    if(Cookies.get('userToken') && isNotRefresh) headers["userSession"] = Cookies.get('userToken')    

    if((state.paramsQuestionnaire && !state.paramsQuestionnaire.codePinConnect ) || validatePin){
      axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/submit_user`,
      {questionnaire: state.paramsQuestionnaire.id, email: mailValue},
      { params: {languageSelected: state.languageSelected, languageDefault: state.languageDefault},
        headers: headers
      })
      .then(async (response) => {
        dispatch(setIdUser(response.data.idUser))
        try {
          // D'abord, obtenez les questions
          const questions = await axiosFunc.getPersonnalQuestions(navigate, dispatch);
      
          // Ensuite, obtenez les réponses de l'utilisateur en utilisant l'idUser
          const answers = await axiosFunc.getAnswersUser(navigate, dispatch, response.data.idUser);
      
          // Puis, obtenez le menu et le menu utilisateur
          const [menu, userMenu] = await Promise.all([
            axiosFunc.getMenu(dispatch),
            axiosFunc.getMenu(dispatch, response.data.idUser),
          ]);
          
          // Utilisez les résultats obtenus
          const firstElement = questions[userMenu[0].id];
          // Votre logique continue ici
          if(state.paramsQuestionnaire.codePinConnect) {
            if(validatePin){
            if(response.data.needThemeSelection){
              navigate(`${process.env.REACT_APP_FRONT_LINK}/menu/${state.paramsQuestionnaire.idUrl ? state.paramsQuestionnaire.idUrl : state.paramsQuestionnaire.id}/${response.data.idUser}`)
            } else {
              navigate(`${process.env.REACT_APP_FRONT_LINK}/${state.paramsQuestionnaire.idUrl ? state.paramsQuestionnaire.idUrl : state.paramsQuestionnaire.id}/${response.data.idUser}/${userMenu[0].id}/${firstElement.pages[0].text.id}/0/${firstElement.pages[0].lots[0].sousquestions[0].id}`)
            }
          }
          } else {
            navigate(`${process.env.REACT_APP_FRONT_LINK}/${state.paramsQuestionnaire.idUrl ? state.paramsQuestionnaire.idUrl : state.paramsQuestionnaire.id}/${response.data.idUser}/${userMenu[0].id}/${firstElement.pages[0].text.id}/0/${firstElement.pages[0].lots[0].sousquestions[0].id}`)
          }
          
        } catch (error) {
          // Gérez les erreurs
          console.error("Une erreur s'est produite:", error);
        }})
      .catch((error) => {
    
    })
      
    } else {
      axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/submit_user`,
      {questionnaire: state.paramsQuestionnaire.id, email: mailValue},
      { params: {languageSelected: state.languageSelected, languageDefault: state.languageDefault},
        headers: headers
      })
      .then((response) => {dispatch(setIdUser(response.data.idUser), dispatch(setModalPin(true)))})
      .catch((error) => {
        if(error.response.status === 403)
          dispatch(setDisplayChangeAnswers(true))
        dispatch(setIdUser(error.response.data.idUser))
      })
      
    }
  },

  unlockSurvey : (dispatch) => {
    const state = store.getState()
    axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/unlock_survey/${state.paramsQuestionnaire.id}/${state.idUser}?languageDefault=${state.languageDefault}&languageSelected=${state.languageSelected}`, {}, {
      headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
  })
  .then((responseAlert) => {
      dispatch(setAlertContainer(responseAlert.data))
      dispatch(setDisplayChangeAnswers(true))
  })
  .catch((error) => {
    allFunctions.createNotification('error', error.message, error.name)
  })
    
  },

  // Récupère les langues diponibles pour le site
  getAllLanguages : (languageSelected, languageDefault, dispatch) => {
        axios.get(`${process.env.REACT_APP_BACK_URL}/preferences/get_languages` , {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`
          },
          params: {
            languageSelected: languageSelected,
            languageDefault: languageDefault
          }
    
        })
        .then((response) => {
            if(response.data.length !== 0) {
              dispatch(setLanguages(response.data.langue));
            }})
        .catch((error) => {console.log(error);})
        .finally(() => {});
  },

  // Récupère les textes de la page d'accueil
  getHomePage : (id,languageSelected,languageDefault,dispatch) => {
    return new Promise((resolve, reject) => {
      
      axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_homepage/${id}` , {
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
        params: {
          languageSelected: languageSelected,
          languageDefault: languageDefault
        },
      })
      .then((response) => {dispatch(setHomepage(response.data.homepage)); resolve(response)})
      .catch((error) => {reject(error)})
    })
  },

  // Enregistre les réponses du questionnaire. Si bool = true, alors c'est la validation et clôture du questionnaire

  sendResponses : (bool, navigate, docForBack, answersData, dispatch) => {
    const state = store.getState()
     axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/submit?languageSelected=${state.languageSelected}&languageDefault=${state.languageDefault}`, {
        idUser: parseInt(state.idUser),
        questionnaire: parseInt(state.paramsQuestionnaire.id),
        answers: answersData,
        isLast: bool,
    }, {
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
        userSession: Cookies.get('localToken')
      },
    },)
    .then((response) => {
      const obj = Object.entries(response.data.answers)
      dispatch(setModif(false))
      if(response.status === 200){
        allFunctions.createNotification('success','Vos réponses ont bien été sauvegardées', 'Enregistrement effectué')
      }
      if(response.status === 202){
        allFunctions.createNotification('success',`Vos réponses ont bien été sauvegardées, cependant, ${response.data.errors.length} questions obligatoires attendent une réponse`, 'Enregistrement effectué')
        response.data.errors.forEach((error, errorIndex) => {
          allFunctions.createNotification('warning',`La question "${error.askText}" dans le chapitre "${error.title}" est une question obligatoire. Merci d'y répondre.`, `Question obligatoire`)
        })
      }
      
        })
        .catch((error) => {
          allFunctions.createNotification('warning', error.response.data.content, error.response.status, )
          if(error.status === 401 && error.redirectToLogin){
            navigate(`${process.env.REACT_APP_FRONT_LINK}/${state.paramsQuestionnaire.idUrl ? state.paramsQuestionnaire.idUrl : state.paramsQuestionnaire.id}`)
          }
        })
        .finally(() => { 
          if(docForBack){
            axiosFunc.sendDocs(docForBack, navigate)
          }
          if (bool){                  
              navigate(`${process.env.REACT_APP_FRONT_LINK}/rapport/${state.paramsQuestionnaire.idUrl ? state.paramsQuestionnaire.idUrl : state.paramsQuestionnaire.id}/${state.idUser}`)
            }
        }) 
  },

  // Envoie les documents téléchargés par l'utilisateur
  sendDocs : (docForBack, navigate) => {
    const formData = new FormData();
    const state = store.getState()
    for (const key in docForBack) {
      formData.append(key, docForBack[key].doc)
    }
    axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/submit_files_answers/${state.paramsQuestionnaire.id}/${state.idUser}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${Cookies.get('token')}`,
        UserSession: Cookies.get('localToken'),
      },
    })
    .then((response) => {
      allFunctions.createNotification('success', 'Vos documents ont bien été reçus', 'Enregistrement des documents')
    })
    .catch((error) => {
      allFunctions.createNotification('warning', error.message, "Erreur")
      if(error.status === 401 && error.redirectToLogin){
        navigate(`${process.env.REACT_APP_FRONT_LINK}/${state.paramsQuestionnaire.idUrl ? state.paramsQuestionnaire.idUrl : state.paramsQuestionnaire.id}`)
      }
    })
  },

  // Envoie le code PIN tapé par l'utilisateur en back pour valider la connexion
  validatePin : async (codePin, dispatch, navigate, setIsGenerate) => {
    const state = store.getState()
    const mail = state.userMail
    try {
     const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/validate_pin/${state.paramsQuestionnaire.id}/${state.idUser}`, {
        code: codePin,
        mail : mail,
      }, {
        params: {
          languageSelected: state.languageSelected,
          languageDefault: state.languageDefault,
        },
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },})
      Cookies.set('localToken', response.data.userSession)
      if(response.data.needCodeReset){
        setIsGenerate(true)
        dispatch(setLoader(false))
      }
      
      if(response.status === 200 && !response.data.needCodeReset) {
        await axiosFunc.login(dispatch, navigate, true)
        dispatch(setLoader(true))
        dispatch(setModalPin(false))
      }
    } catch (error) {
      dispatch(setLoader(false))
      dispatch(setReject(true))
    }

  },
  
  // Envoie le nouveau code PIN de l'utilisateur au back pour enregistrement
  changePersonnalCodePin : (event,dispatch,personalCodePin,navigate,setModalPin) => {
      const state = store.getState()
      if(event.length === 4){
        if(event === personalCodePin){
          dispatch(setLoader(true))
              axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/validate_pin/${state.paramsQuestionnaire.id}/${state.idUser}`, 
              {
                  code: personalCodePin,
                  codeReset: true,
                  mail: null,
              },
              {
                  params: {
                    languageSelected: state.languageSelected,
                    languageDefault: state.languageDefault,
                  },
                  headers: {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                  },}
              )
              .then((response) => {
                allFunctions.createNotification('success','Vous allez être redirigé vers le questionnaire, merci de patienter', "En cours de traitement")
                axiosFunc.login(dispatch, navigate, true)
                dispatch(setModalPin(false))
              })
              .catch((error) => {console.log(error)})
              .finally(() => {dispatch(setLoader(false))})

          } else {
              allFunctions.createNotification('warning','Les deux codes PIN ne correspondent pas, merci de réessayer', "Erreur : Codes PIN différents")
          }
      }
  },

  askNewPin : (bool, navigate, setModalPin,setForgotPin,setIsGenerate,setPinError, dispatch,setReject,setDisplayAskNewPinButton) => {
    const state = store.getState()
    const mail = document.getElementById('mailNewInput')
    if(bool){
        axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/validate_pin/${state.paramsQuestionnaire.id}`, {
            mail: mail.value,
            code: null,
            codeReset: bool ? bool : false,
    }, {headers: {
        Authorization: `Bearer ${Cookies.get('token')}`
      },
      params: {
            languageSelected: state.languageSelected,
            languageDefault: state.languageDefault,
          }})
    .then((response) => {
        Cookies.set('localToken', response.data.userSession)
        if(response.data.needCodeReset){
            axiosFunc.login(dispatch, navigate)
            if(!response.data.selectTheme){
                    navigate(`${process.env.REACT_APP_FRONT_LINK}/menu/${state.paramsQuestionnaire.idUrl ? state.paramsQuestionnaire.idUrl : state.paramsQuestionnaire.id}/${state.idUser}`)
                setModalPin(false)
            }
            } else {
            allFunctions.createNotification('success','Un nouveau code PIN vient de vous être envoyé par mail, merci de la saisir', "Nouveau code PIN")
            dispatch(setForgotPin(false))
            setIsGenerate(false)

        }
    })
    .catch((error) => {
        setPinError(true)
      })
    .finally(() => {})    
       setModalPin(true)
        dispatch(setForgotPin(false))
        // dispatch(setReject(false))
        setDisplayAskNewPinButton(false)
    } else {
        dispatch(setForgotPin(true))
    }
  },

  // Débloque un questionnaire
  validChange : (setDisplayModal, dispatch) => {
  const state = store.getState()
  axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/unlock_survey/${state.paramsQuestionnaire.id}/${state.idUser}`, {forceUnlock: true}, {
      headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
  })
  .then((response) => {
      setDisplayModal(false)
      dispatch(setDisplayChangeAnswers(false))
      allFunctions.createNotification('success','Vous pouvez à nouveau modifier vos réponses', 'Veuillez vous reconnecter')
  })
  },

  // Récupère les données à afficher sur les radars
  recupRadar : (params,dispatch,setColorSlide,rgbToHex,setFeedBack,setIdAnswerForFeedBack,setDisplayModal,navigate,setLoadedData,setIsLoaded, setTextModal) => {
  const state = store.getState()
  if(params && params.questionnaire && params.idUser){
      axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_report/${state.paramsQuestionnaire.id}/${params.idUser}?languageDefault=${state.languageDefault}&languageSelected=${state.languageSelected}`,
      {   headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
          UserSession: Cookies.get('localToken')
      }}) 
      
      .then ((response) => {
          if(response.data.content && response.data.content.series[0] && response.data.content.series[0].data[2]) {
              setColorSlide(rgbToHex(`rgb(${response.data.content.series[0].data[2].areaStyle})`))
          }
          dispatch(setProfilForEnd(response.data.content.series[0].profils))
          setFeedBack(response.data.content.feedback)
          dispatch(setRapport(response.data.content))
          if(response.data.content.feedback){
              setIdAnswerForFeedBack(response.data.content.feedback.answerChoice)
          }
     dispatch(setSummaryScore(response.data.content.summaryScore))

     
     if(response.data.content.recontact == null){
      setDisplayModal(true)
  } else if (response.data.content.recontact){
      setDisplayModal(false)
  }
    setTextModal(response.data.content.endingMessage)
  })

    .catch((error) => {console.log(error); 
      if(error.status === 401 && error.redirectToLogin){
      navigate(`${process.env.REACT_APP_FRONT_LINK}/${state.paramsQuestionnaire.idUrl ? state.paramsQuestionnaire.idUrl : state.paramsQuestionnaire.id}`)
    }})
      .finally(() => { setLoadedData(true); setIsLoaded(true)})
  }
    
  },

  getToken : (dispatch) => {
    return new Promise ((resolve, reject) => {
      axios.get(`${process.env.REACT_APP_BACK_URL}/auth/get_token`, 
          {headers: {
            appname: process.env.REACT_APP_NAME,
            apppass: process.env.REACT_APP_PASS,
          },})
        .then ((response) => {
          Cookies.set('token', response.data.token);
          resolve(response)
          })
        .catch((error) => {console.log(error); reject(error)})
        .finally(() => {
          dispatch(setToken(true))
        })
    })
  },

  recontact : (bool) => {
    const state = store.getState()
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.REACT_APP_BACK_URL}/questionnaire/recontact/${state.paramsQuestionnaire.id}/${state.idUser}?recontact=${bool}`, '', {headers: {
      Authorization: `Bearer ${Cookies.get('token')}`,
      UserSession: Cookies.get('localToken')
    }})
     .then((response) => {resolve(response)})
     .catch((error) => {reject(error)}); 
      })
  },

  getAnswersUser : async (navigate, dispatch, idUser) => {
    const state = store.getState()
      try {
        let response = await axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_answers_identity/${state.paramsQuestionnaire.id}/${idUser}`,
          {
            params: {
              languageSelected: state.languageSelected,
              languageDefault: state.languageDefault,
            },
            headers: {
              Authorization: `Bearer ${Cookies.get('token')}`,
              UserSession: Cookies.get('localToken')
            },} )
          for (const [themeKey, themeValue] of Object.entries(response.data.answers)){
            for (const [pageKey, pageValue] of Object.entries(themeValue)) {
              const findPage = state.allQuestions[themeKey].pages.find(obj => parseInt(obj.text.id) === parseInt(pageKey))
              const findPageIndex = state.allQuestions[themeKey].pages.findIndex(obj => parseInt(obj.text.id) === parseInt(pageKey))
              for (const [questionKey, questionValue] of Object.entries(pageValue)){
                findPage.lots.map((lot, lotIndex) => {
                  const indexQuestion = lot.sousquestions.findIndex(obj => obj.id === parseInt(questionKey))
                  dispatch(setAllQuestionsAnswers(questionValue, themeKey, findPageIndex, lotIndex, indexQuestion, 'answer'))
                })
              }
            }
          }
      } catch (error) {
        navigate(`${process.env.REACT_APP_FRONT_LINK}/${state.paramsQuestionnaire.idUrl ? state.paramsQuestionnaire.idUrl : state.paramsQuestionnaire.id}`)
      }
  },

  getRapportChapter : (setShowCheckboxColumn, setData, setReco, dispatch, idTheme) => {
    const state = store.getState()
    axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/component/get_report/${idTheme}/${state.idUser}`, {
      params: {
        languageSelected: state.languageSelected,
        languageDefault: state.languageDefault,
      },
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
        UserSession: Cookies.get('localToken')
      },})
      .then((response) => {
        response.data.content.showCheckboxReco ? setShowCheckboxColumn(response.data.content.showCheckboxReco) : setShowCheckboxColumn(response.data.content.profils.showCheckboxColumn)
        setData(response.data.content.data)
        setReco(response.data.content.recommandations)
        dispatch(setSummaryScore(response.data.content.summaryScore))
    })
      .catch((error) => {
        console.log(error)
      })
  },
  
  getMenu : async (dispatch, idUser) => {
    const state = store.getState()
    try {
      let response = await axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get_themes/${state.paramsQuestionnaire.id}${idUser ? '/'+ idUser : ''}`, 
      {
        params: {
          languageSelected: state.languageSelected,
          languageDefault: state.languageDefault,
        },
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
          UserSession: Cookies.get('localToken')
        },}
    )
      if(idUser){
              dispatch(setUserMenu(response.data.themes))
            } else {
              dispatch(setMenu(response.data.themes))
            }
      
      return response.data.themes

    } catch (error) {
      allFunctions.createNotification('error', error.message, error.status)
    }
    
    
  },

  getTextes : (dispatch) => {
    const state = store.getState()
    return new Promise((resolve, reject) => {
       axios.get(`${process.env.REACT_APP_BACK_URL}/preferences/translations/get_text/app?languageSelected=${state.languageSelected}`,
    {headers: {
      Authorization: `Bearer ${Cookies.get('token')}`
    }})
    .then((response) => { 
      dispatch(setGeneriquesTextes(response.data.app))
      resolve(response)
    })
    .catch((error) => {
      reject(error)
    })
    })
   
  },
  getParamsQuestionnaire : (questionnaire, dispatch) => {
    const state = store.getState()
    return new Promise((resolve, reject) => {
       axios.get(`${process.env.REACT_APP_BACK_URL}/questionnaire/get/${questionnaire}`,
    {
      params: {
        languageSelected: state.languageSelected,
        languageDefault: state.languageDefault,
      },
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },}
    )
    .then((response) => { 
      dispatch(setParamsQuestionnaire(response.data))
      resolve(response)
    })
    .catch((error) => reject(error))
    })
   
  },

  verifUserExist : (idUser) => {
    let exist = false 
    const state = store.getState()
    axios.get(`${process.env.REACT_APP_BACK_URL}/identite/get/${idUser}`,
      {
        params: {
          languageSelected: state.languageSelected,
          languageDefault: state.languageDefault,
        },
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },}
    )
    .then((response) => {
      exist = true
    })
    .catch(error => exist = false)
    .finally(() => exist ? console.log(exist) : console.log(exist))
  },

  sendSelectedMenu : (bool, dispatch, setDisplayModal, navigate) => {
    const state = store.getState()
    axios.post(`${process.env.REACT_APP_BACK_URL}/questionnaire/validate_selection/${state.paramsQuestionnaire.id}/${state.idUser}`, { selectedIds: state.userMenu, confirmationSelect: bool ? bool : false },{ headers: { Authorization: `Bearer ${Cookies.get('token')}`, UserSession: Cookies.get('localToken')}, params: {
      languageSelected: state.languageSelected,
      languageDefault: state.languageDefault,
    } })
  .then(async (response) => {
      if(response.status === 202){
          setDisplayModal(true)
  }
      if (response.status === 200){
        setDisplayModal(false)
        try {
          const response = await axiosFunc.getPersonnalQuestions(navigate, dispatch); // Attente de la résolution ici
         const ObjResponse = Object.entries(response)[0]
         console.log()
         navigate(`${process.env.REACT_APP_FRONT_LINK}/${state.paramsQuestionnaire.idUrl ? state.paramsQuestionnaire.idUrl : state.paramsQuestionnaire.id}/${state.idUser}/${ObjResponse[0]}/${ObjResponse[1].pages[0].text.id}/0/${ObjResponse[1].pages[0].lots[0].sousquestions[0].id}`)
        } catch (error) {
          console.error('Failed to get questions:', error);
        }
      }})
    .catch((error) => {console.log(error); 
      if(error.status === 401 && error.redirectToLogin){
        navigate(`${process.env.REACT_APP_FRONT_LINK}/${state.paramsQuestionnaire.idUrl ? state.paramsQuestionnaire.idUrl : state.paramsQuestionnaire.id}`)
      }})
    .finally(() => {})
  }

}

export default axiosFunc