import { Card } from 'react-bootstrap'
import './profil.scss'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import TextComponent from '../Page/TextComponent'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Gaug from '../Graph/Radar/Gaug Ring'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Puff } from 'react-loader-spinner'
import Recap from './recap'
import Recommandations from './recommandations'
import allFunctions from '../Fonctions/functions'
import axiosFunc from '../Fonctions/axios'

const Rapport = ({actual, languageDefault, languageSelected, summaryScore}) => {
    const { menuId } = useParams()
    const { idUser } = useParams()
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [reco, setReco] = useState()
    const [showCheckboxColumn, setShowCheckboxColumn] = useState([])
    const [profil, setProfil] = useState()
   
    useEffect(() => {
        setTimeout(() => {
            axiosFunc.getRapportChapter(setShowCheckboxColumn, setData, setReco, dispatch, menuId)
        }, 500);
    }, [])

    const addReco = (reco, event) => {
        const url = `${process.env.REACT_APP_BACK_URL}/questionnaire/engage_recommendation/${reco.idQuestion}/${idUser}`;
        const data = {
            id: reco.idReco,
            check: event.target.checked,
        };
    
        const headers = {
            Authorization: `Bearer ${Cookies.get('token')}`,
            UserSession: Cookies.get(('localToken')),
        };
    
        axios.post(url, data, { headers, params: {
            languageSelected: languageSelected,
            languageDefault: languageDefault,
          }  })
            .then(response => {
                if(response.status === 200){
                    allFunctions.createNotification('warning',response.data.text, response.data.title)
                } else if (response.status === 202){
                    allFunctions.createNotification('success',response.data.text, response.data.title)
                }
            })
            .catch(error => {
                // Gérez les erreurs si la requête échoue
                allFunctions.createNotification('Error', 'Erreur lors de l\'envoi des données au backend', error);
            });
        };
        
        useEffect(()=> {
            if(data && data[0]) { 
                setProfil(actual.menu.profil.find(obj => summaryScore.totalUser >= obj.necessaryPoints[0] && summaryScore.totalUser <= obj.necessaryPoints[1])) 
            }
        }, [data, summaryScore])
        
        
        return (
            data && data[0] && profil ? 
            <div className="rapportChapt">
                <div className="questions__swipes reverse" onClick={() => {allFunctions.swipeMoins(navigate)}}></div>
                <div className='rapportChapt__container'>
                    <div className='rapportChapt__graph'>
                        <Gaug dataBack={data} />
                        <Recap header={summaryScore.header} data={summaryScore.data} totalUser={summaryScore.totalUser} totalAdmin={summaryScore.totalAdmin} totalLabelUser={summaryScore.totalUserGlobal} />
                    </div>
                    <div className='questions__card rapportChapt__conseils'>  
                        <Card.Title className='rapportChapt__card__title'>{profil && profil.title}</Card.Title>
                        <Card.Text className='rapportChapt__card__text'><TextComponent text={profil && profil.text} /></Card.Text>
                    </div>
                    {reco ?
                    reco.map((r) => {
                        if(r.idChapitre == menuId){
                            return(
                                <Recommandations showCheckboxColumn={showCheckboxColumn} data={r} addReco={addReco} /> 
                            )
                    }
                })
                : null }
                </div>
                <div className="questions__swipes" onClick={() => {allFunctions.swipePlus(navigate, dispatch, params)}}></div>
                    
            
        
        
    </div>
    : <div className="loader">
    <Puff 
        visible={true}
        color="rgb(218,91,21)"
        height=""
        width=""
        />
</div>
    )
}

const mapStateToProps = (state) => ({
    menu: state.menu,
    allQuestions: state.allQuestions,
    languageSelected: state.languageSelected,
    languageDefault: state.languageDefault,
    currentPath: state.currentPath,
    idQuestionnaire: state.paramsQuestionnaire.id,
    idCorrespond: state.idCorrespond,
    summaryScore: state.summaryScore,
    profilForEnd: state.profilForEnd,
    actual: state.actual,
    });
  
  
  export default connect(mapStateToProps)(Rapport);