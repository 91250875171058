import { Slider } from "@mui/material"
import { connect, useDispatch } from "react-redux";
import allFunctions from "../../Fonctions/functions";
import { useParams } from "react-router-dom";
import { useEffect } from "react";


const ReponseSlide = ( { sousquestion}) => {
    const dispatch = useDispatch() 
    const params = useParams()

    const changeSelectValue = (event) => {
      const pointsAttribues = Math.ceil((event.target.value - parseInt(sousquestion.options[0]))/(parseInt(sousquestion.options[1]) - parseInt(sousquestion.options[0])) * sousquestion.pointsValue);

        let payload = {}
        payload['value'] = pointsAttribues ? pointsAttribues : 0
        payload['textResponse'] = event.target.value ? event.target.value : 0

        allFunctions.changeAnswer(dispatch, params, sousquestion.askTypes, payload, sousquestion.id)
          }

          useEffect(() => {
            if(!sousquestion.answer){
              changeSelectValue({target : { value : 0}})
            }
          }, [])
        
    return (
        <div className='slider__container'>
        <p className='slider__text'>{sousquestion.options[0]}</p>
        <Slider
            onChange={(event) => {changeSelectValue(event)}}
            valueLabelDisplay="auto"
            defaultValue={sousquestion.answer && sousquestion.answer.textResponse ? parseInt(sousquestion.answer.textResponse) : 0}
            min={parseInt(sousquestion.options[0])}
            className='questions__card__slide'
            marks={sousquestion.isGraduated ? true : false}
            step={sousquestion.graduation ? sousquestion.graduation : 1}
            max={parseInt(sousquestion.options[1])} />
        <p className='slider__text'>{sousquestion.options[1]}</p>
        </div>
    )
}

const states = (state) => ({
   });


 export default connect(states)(ReponseSlide)
