export const setMenu = (payload) =>                     ({type: 'SET_MENU', payload});
export const setUserMenu = (payload) =>                 ({type: 'SET_USER_MENU', payload});
export const setChangeUserMenu = (payload) =>           ({type: 'SET_CHANGE_USER_MENU', payload})
export const setReject= (payload) =>                    ({type: 'SET_ERROR_PIN', payload})
export const setUserMail = (payload) =>                 ({type: 'SET_USER_MAIL', payload})
export const setMenuSelect = (payload) =>               ({type: 'SET_MENU_SELECT', payload});
export const setPath = (payload) =>                     ({type: 'SET_PATH', payload});
export const setStep = (payload) =>                     ({type: 'SET_STEP', payload});
export const setLanguages = (payload) =>                ({type: 'SET_LANGUAGES', payload});
export const setLanguageSelected = (payload) =>         ({type: 'SET_LANGUAGE_SELECTED', payload});
export const setAllQuestions = (payload) =>             ({type: 'SET_ALL_QUESTIONS', payload});
export const setIdUser = (payload) =>                   ({type: 'SET_ID_USER', payload});
export const setIdQuestionnaire = (payload) =>          ({type: 'SET_ID_QUESTIONNAIRE', payload});
export const setIdMenu = (payload) =>                   ({type: 'SET_ID_MENU', payload});
export const setIdPage = (payload) =>                   ({type: 'SET_ID_PAGE', payload});
export const setIndexLot = (payload) =>                 ({type: 'SET_INDEX_LOT', payload});
export const setIdQuestion = (payload) =>               ({type: 'SET_ID_QUESTION', payload});
export const setIsLast = (payload) =>                   ({type: 'SET_IS_LAST', payload});
export const setNeedRapport = (payload) =>              ({type: 'SET_NEED_RAPPORT', payload});
export const setRapport = (payload) =>                  ({type: 'SET_RAPPORT', payload});
export const setProfilForEnd = (payload) =>             ({type: 'SET_PROFIL_FOR_END', payload});
export const setFonction = (payload) =>                 ({type: 'SET_FONCTION', payload});
export const setFirstname = (payload) =>                ({type: 'SET_FIRSTNAME', payload});
export const setLastname = (payload) =>                 ({type: 'SET_LASTNAME', payload});
export const setDirigeantLastname = (payload) =>        ({type: 'SET_DIRIGEANT_LASTNAME', payload});
export const setDirigeantFirstname = (payload) =>       ({type: 'SET_DIRIGEANT_FIRSTNAME', payload});
export const setDirigeantMail = (payload) =>            ({type: 'SET_DIRIGEANT_MAIL', payload});
export const setModif = (payload) =>                    ({type: 'SET_MODIF', payload});
export const setToken = (payload) =>                    ({type: 'SET_TOKEN', payload});
export const setHomepage = (payload) =>                 ({type: 'SET_HOMEPAGE', payload});
export const setThemes = (payload) =>                   ({type: 'SET_THEMES', payload});
export const setSelectedThemes = (payload) =>           ({type: 'SET_SELECTED_THEMES', payload})
export const setDisplayUnderMenu = (payload) =>         ({type: 'SET_DISPLAY_UNDERMENU', payload})
export const setIdChapitre = (payload) =>               ({type: 'SET_ID_CHAPITRE', payload})
export const setIdCorrespond = (payload) =>             ({type: 'SET_ID_CORRESPOND', payload})
export const setDisplayReportMenu = (payload) =>        ({type: 'SET_DISPLAY_REPORT_MENU', payload})
export const setThemeSelectionActive = (payload) =>     ({type: 'SET_THEME_SELECTION_ACTIVE', payload})
export const setSummaryScore = (payload) =>             ({type: 'SET_SUMMARY_SCORE', payload})
export const setTableDisplay = (payload, id) =>         ({type: 'SET_TABLE_DISPLAY', payload, id})
export const setLoader = (payload) =>                   ({type: 'SET_LOADER', payload})
export const setAnswersForBack = (payload) =>           ({type: 'SET_ANS_BACK', payload})
export const setPreciseAnswersForBack = (payload, key)=>({type: `SET_ANS_BACK_${key.toUpperCase()}`, payload, case: key.toUpperCase()})
export const setSaveNewAnswers = (payload) =>           ({type: 'SET_SNA', payload})
export const setPreciseNewAnswers = (payload, key) =>   ({type: `SET_SNA_${key.toUpperCase()}`, payload, case: key.toUpperCase()})
export const setAlertContainer = (payload) =>           ({type: 'SET_ALERT_CONTAINER', payload})
export const setActual = (payload, key) =>              ({type: `SET_ACTUAL_${key.toUpperCase()}`, payload, case: key.toUpperCase(), key : key})
export const setActualLotSpe = (payload, index, key) => ({type: 'SET_ACTUAL_LOT_SPE', payload, index, key})
export const setNext = (payload, key) =>                ({type: `SET_NEXT_${key.toUpperCase()}`, payload, case : key.toUpperCase(), key : key})
export const setModalPin = (payload) =>                 ({type: 'SET_MODAL_PIN', payload})
export const setForgotPin = (payload) =>                ({type: 'SET_FORGOT_PIN', payload})
export const setUnderMenu = (payload) =>                ({type: 'SET_UNDERMENU', payload})
export const setGeneriquesTextes = (payload) =>         ({type: 'SET_GENERIQUES_TEXTES', payload})
export const setParamsQuestionnaire = (payload) =>      ({type: 'SET_PARAMS_QUESTIONNAIRE', payload})
export const setLanguage = (payload) =>                 ({type: 'SET_LANGUAGE', payload})
export const setDisplayChangeAnswers = (payload) =>                 ({type: 'SET_DISPLAY_CHANGE_ANSWERS', payload})
export const setAllQuestionsAnswers = (payload, idMenu, pageIndex, lotIndex, questionIndex, key) => ({type: 'SET_ALL_QUESTIONS_ANSWERS', payload, idMenu, pageIndex, lotIndex, questionIndex, key})

export const setAllLevel = (payload, name, first, second, third, fourth) => { return ({
    type: `SET_ALL_LEVEL${first ? `_1` : ''}${second ? `_2` : ''}${third ? `_3` : ''}${fourth ? `_4` : ''}`,
    payload,
    first: first ? first : null,
    second: second ? second : null,
    third: third ? third : null,
    fourth: fourth ? fourth : null,
    name: name,
    key : fourth ? fourth : third ? third : second ? second : first ? first : name,
  })};