import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { setDirigeantFirstname, setDirigeantLastname, setModif } from "../../../actions/action";
import allFunctions from "../../Fonctions/functions";
import { useParams } from "react-router-dom";

const ReponseTextarea = ({sousquestion, fonction, dirigeantlastname, dirigeantfirstname, actual}) => {
    const sousquestionLabel = sousquestion.label
    const dispatch = useDispatch()
    const params = useParams()
    const [destruct, setDestruct] = useState(true)

    useEffect(() => {
        /* if(saveNewAnswers && saveNewAnswers[idMenu]){
            const findFirstNameObj = lot.find(obj => obj.identityType === 'firstname')
            const findFirstNameValue = findFirstNameObj && saveNewAnswers[idMenu][page.text.id] && saveNewAnswers[idMenu][page.text.id][findFirstNameObj.id] ? saveNewAnswers[idMenu][page.text.id][findFirstNameObj.id].textResponse : null
            const findLastNameObj = lot.find(obj => obj.identityType === 'lastname')
            const findLastNameValue = findLastNameObj && saveNewAnswers[idMenu][page.text.id] && saveNewAnswers[idMenu][page.text.id][findLastNameObj.id] ? saveNewAnswers[idMenu][page.text.id][findLastNameObj.id].textResponse : null
            
            if(fonction && findFirstNameObj && findLastNameObj){
                dispatch(setDirigeantFirstname(findFirstNameValue))
                dispatch(setDirigeantLastname(findLastNameValue))
            } else if (!fonction) {
                dispatch(setDirigeantFirstname(null))
                dispatch(setDirigeantLastname(null))
            }
            
            if(sousquestion.identityType === 'dirigeantfirstname' && dirigeantfirstname){
                allFunctions.gestionSaveNewAnswers(dispatch, [
                    {value: dirigeantfirstname, key: 'textResponse'},
                    {value: questionValue, key: 'value'}
                ], idMenu.toString(), page.text.id.toString(), sousquestion.id.toString())
                
            } else if (sousquestion.identityType === 'dirigeantlastname' && dirigeantlastname){
                allFunctions.gestionSaveNewAnswers(dispatch, [
                    {value: dirigeantlastname, key: 'textResponse'},
                    {value: questionValue, key: 'value'}
                ], idMenu.toString(), page.text.id.toString(), sousquestion.id.toString())
            }
        } */
    }, [fonction])

    useEffect(() => {
        setDestruct(true)
        setTimeout(() => {
            setDestruct(false)
        }, 0);
    }, [params])

    return (
        !sousquestion.isWide ? 
        <input className="questions__card__input" 
        placeholder={sousquestionLabel ? sousquestionLabel : null} 
        defaultValue= {!destruct && sousquestion.answer && sousquestion.answer.textResponse ? sousquestion.answer.textResponse : null} 
        onKeyUp={(event) => {allFunctions.changeAnswer(dispatch, params, sousquestion.askTypes, event.target.value, sousquestion.id)}} />
        : 
        <textarea className="questions__card__textarea"
        placeholder={sousquestionLabel ? sousquestionLabel : null} 
        defaultValue= {!destruct && sousquestion.answer && sousquestion.answer.textResponse ? sousquestion.answer.textResponse : null} 
        onKeyUp={(event) => {allFunctions.changeAnswer(dispatch, params, sousquestion.askTypes, event.target.value, sousquestion.id)}} />
    )
}

const mapStateToProps = (state) => ({
    languageSelected: state.languageSelected,
    fonction: state.fonction,
    dirigeantlastname: state.dirigeantlastname,
    dirigeantfirstname: state.dirigeantfirstname,
    dirigeantmail: state.dirigeantmail,
    idMenu : state.idMenu,
    actual: state.actual,
 });
  
  export default connect(mapStateToProps)(ReponseTextarea);