import { Card } from 'react-bootstrap'
import './questions.scss'
import flowersLeft from '../../assets/img/valliance/Feuille_gauche.bmp'
import { connect, useDispatch } from 'react-redux'
import React, {useEffect, useState } from 'react'
import CardGlobal from './Card'
import { useNavigate, useParams } from 'react-router-dom'
import allFunctions from '../Fonctions/functions'
import { Puff } from 'react-loader-spinner'
import { setIsLast } from '../../actions/action'


const Page = ( {actual, userMenu, generiquesTextes, isLast} ) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [destruct, setDestruct] = useState(true)
    const params = useParams()

      useEffect(() => {
        allFunctions.recursiveAnswers(dispatch, params.menuId, params.idPage, params.indexLot, params.idQuestion)
    }, [params.idQuestion])
      

    useEffect(() => {
        setDestruct(true)
        setTimeout(() => {
            setDestruct(false)
        }, 0);
    }, [params.idQuestion])

    return (
    !destruct ? 
        actual && actual.menu && actual.menu ? 
        <div className="questions">
            <div className="questions__swipes reverse" onClick={() => {allFunctions.swipeMoins(navigate)}}></div>
            <div className="questions__card__global">
                <Card.Title className='questions__title__page' >{actual && actual.menuName ? actual.menuName : null}</Card.Title>
                <Card className="questions__card z">
                    <Card.Img className="questions__img" src={flowersLeft} />
                    <Card.Title className="questions__title">{actual.pageIndex+1} - {actual.page.text.title}</Card.Title>
                    <Card.Text className="questions__description">{actual.page.text.description}</Card.Text>
                </Card>
                <CardGlobal />
                   
                </div>
            {!isLast ? <div className="questions__swipes" onClick={() => {allFunctions.swipePlus(navigate, dispatch, params)}}></div> : null}
            </div>
    : <div className="loader">
    <Puff 
        visible={true}
        color="rgb(218,91,21)"
        height=""
        width=""
        />
</div> : null) 
}

const mapState = (state) => ({
    generiquesTextes: state.generiquesTextes,
    actual: state.actual,
    userMenu: state.userMenu,
    isLast: state.isLast,
    });
  
  
  export default connect(mapState)(Page);
