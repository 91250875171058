/* eslint-disable no-useless-escape */
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { setIdQuestion, setIdMenu, setIdPage, setIndexLot, setIdChapitre, setNext, setUnderMenu, setActual, setIdQuestionnaire, setIdUser, setAllQuestionsAnswers, setTableDisplay, setModif, setIsLast } from '../../actions/action';
import store from '../../store';
import axiosFunc from './axios';

const allFunctions = {

  // Gestionnaire de notifications
  createNotification : (type, text, title, time) => {
    switch (type) {
      case 'info':
        NotificationManager.info(text, title, time ? time : 5000);
        break;
      case 'success':
        NotificationManager.success(text, title, time ? time : 5000);
        break;
      case 'warning':
        NotificationManager.warning(text, title, time ? time : 5000);
        break;
      case 'error':
        NotificationManager.error(text, title, time ? time : 5000);
        break;
      default: break;
    }
  },

// ****************************** VERIFICATION ****************************** //
// Vérifier si le texte envoyé (mail) est bel et bien un format mail
  verifAt: (mail,setDisplayBeginButton) => {
    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (regexEmail.test(mail)) {setDisplayBeginButton(true);return (true)}  
    else { setDisplayBeginButton(false)}},

// ****************************** NAVIGATION ****************************** //
// Retour page arrière
  swipeMoins: (navigate) => {navigate(-1)},


// ****************************** GESTION D'OBJETS ****************************** //

createTableQuestion : (data) => {
      const subQuestionIds = []
      if(data.questions && Array.isArray(data.questions)){
        data.questions.forEach(question => {
          if(question.pages && Array.isArray(question.pages)) {
            question.pages.forEach(page => {
              if (page.lots && Array.isArray(page.lots)) {
                page.lots.forEach((lot) => {
                  if(lot.sousquestions && Array.isArray(lot.sousquestions)) {
                      const lotQuestionsIDs = lot.sousquestions
                      .filter((sousquestion) => sousquestion.id && !sousquestion.isOtherQuestion)
                      .map((sousquestion) => sousquestion.id)
                      if(lotQuestionsIDs.length > 0) {
                        subQuestionIds.push(lotQuestionsIDs)
      }}})}})}});}
      return subQuestionIds
  },

// Vérifie si version PC ou Mobile
  detectBrowser : () => {
      // Opera 8.0+
      var isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0
      if (isOpera) return 'opera'
      // Firefox 1.0+
      var isFirefox = typeof InstallTrigger !== 'undefined';
      if (isFirefox) return 'firefox'
      // Safari 3.0+ "[object HTMLElementConstructor]" 
      var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
      if (isSafari) return 'safari'
      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/false || !!document.documentMode;
      if (isIE) return 'internetexplorer'
      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia;
      if (isEdge) return 'edge'
      // Chrome 1 - 79
      var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
      if (isChrome) return 'chrome'
      // Edge (based on chromium) detection
      var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") !== -1);
      if (isEdgeChromium) return 'edge'
      // Blink engine detection
      var isBlink = (isChrome || isOpera) && !!window.CSS;
      if (isBlink) return 'blink'
    
      return 'chrome'; // default
  },


// Vérifie si l'input mail est bien au format mail
  verifAtNewAsk : (mail,setDisplayAskNewPinButton) => {
    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (regexEmail.test(mail)) {
        setDisplayAskNewPinButton(true)
        return (true)
    } else {
        setDisplayAskNewPinButton(false)
    }
  },

  setInfosWeb : (dispatch, params) => {
    return new Promise((resolve, reject) => {
      try {
        dispatch(setIdQuestion(params.idQuestion))
        dispatch(setIdMenu(params.menuId))
        dispatch(setIdPage(params.idPage))
        dispatch(setIndexLot(params.indexLot))
        dispatch(setIdChapitre(params.idChapitre))
        dispatch(setIdQuestionnaire(params.idQuestionnaire))
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  },

  setActualQuestionnaire : (dispatch, params) => {
    const state = store.getState()
    if(params && params.menuId){
      const findMenu = state.allQuestions[params.menuId]
      const findMenuIndex = Object.keys(state.allQuestions).findIndex(obj => obj === params.menuId)
      const findPage = findMenu.pages.find(page => page.text.id === parseInt(params.idPage))
      const findPageIndex = findMenu.pages.findIndex(page => page.text.id === parseInt(params.idPage))
      const findLot = findPage.lots[params.indexLot]
      const findMenuName = state.menu.find(menu => menu.id === parseInt(params.menuId)).title
      findMenu['needRapport'] = state.menu.find(menu => menu.id === parseInt(params.menuId)).needRapport
      dispatch(setActual(findMenuName, 'menuName'))
      dispatch(setActual(findPage, 'page'))
      dispatch(setActual(findMenu, 'menu'))
      dispatch(setActual(findLot.sousquestions, 'lot'))
      dispatch(setActual(params.indexLot, 'lotIndex'))
      dispatch(setActual(findPageIndex, 'pageIndex'))
      dispatch(setActual(findMenuIndex, 'menuIndex'))
    }

  },

  setNextQuestion : (dispatch, params) => {
    const state = store.getState()
    if(state.idQuestion && state.idMenu && state.idPage && state.indexLot){
      // dispatch(setNext(state))
     if(state.actual.page && state.actual.page.lots[state.indexLot+1]){
       dispatch(setNext(state.indexLot+1, 'lotIndex'))
       dispatch(setNext(state.actual.page.lots[state.indexLot+1], "lot"))
     } else { 
      dispatch(setNext(0, 'lotIndex')); 
      dispatch(setNext(null, 'lot'))};

     if(state.actual.menu && state.actual.menu.pages[state.actual.pageIndex+1]){
      dispatch(setNext(state.actual.pageIndex+1, 'pageIndex'))
      dispatch(setNext(state.actual.menu.pages[state.actual.pageIndex+1], 'page'))
     } else { 
      dispatch(setNext(0, 'pageIndex')); 
      dispatch(setNext(null, 'page'))
    }      
    if(state.allQuestions) {
      const ObjQuestions = Object.entries(state.allQuestions)
      if(ObjQuestions[state.actual.menuIndex+1]){
        dispatch(setNext(ObjQuestions.findIndex((obj, objIndex) => parseInt(obj[0]) === parseInt(ObjQuestions[state.actual.menuIndex+1][0]) ), 'menuIndex'))
        dispatch(setNext(ObjQuestions[state.actual.menuIndex+1][1], 'menu'))
        dispatch(setNext(ObjQuestions[state.actual.menuIndex+1][0], 'menuId'))
      } else {
        dispatch(setNext(null, 'menuIndex'))
        dispatch(setNext(null, 'menu'))
        dispatch(setNext(null, 'menuId'))
      }
    } else { 
      dispatch(setNext(0, 'menuIndex')); 
      dispatch(setNext(null, 'menu'))
    }
    }
    if(!state.idUser){dispatch(setIdUser(params.idUser))}
  },
  
  swipePlus: (navigate, dispatch, params) => {
    const state = store.getState()
    const profil = state.path.includes('profil') ? true : false

    let canNavigate = true
    let link = ''
    let beginLink = `${process.env.REACT_APP_FRONT_LINK}/${state.paramsQuestionnaire.idUrl ? state.paramsQuestionnaire.idUrl : state.paramsQuestionnaire.id}/${state.idUser}`
    state.actual.lot.forEach(question => {
      if((question.required && !question.answer) || (question.required && question.answer && !question.answer.textResponse) ){        
            allFunctions.createNotification('warning', `La réponse à la question "${question.asks}" est obligatoire`, 'Question obligatoire')
            canNavigate = false
            
      } else {
       if(state.next.lot){ 
         link = `${beginLink}/${params.menuId}/${state.actual.page.text.id}/${state.next.lotIndex}/${state.next.lot.sousquestions[0].id}`
        }
        else if (state.next.page){
          link = `${beginLink}/${params.menuId}/${state.next.page.text.id}/${state.next.lotIndex}/${state.next.page.lots[0].sousquestions[0].id}`
        }
        else if (state.actual.menu.needRapport && !profil){
            link = `${beginLink}/${params.menuId}/${state.idPage}/0/${state.idQuestion}/profil`
        } else if (state.next.menu){
          link = `${beginLink}/${state.next.menuId}/${state.next.menu.pages[0].text.id}/0/${state.next.menu.pages[0].lots[0].sousquestions[0].id}` 
        } else { 
          link = `${process.env.REACT_APP_FRONT_LINK}/rapport/${state.paramsQuestionnaire.idUrl ? state.paramsQuestionnaire.idUrl : state.paramsQuestionnaire.id}/${state.idUser}`
        } 
        }
    })

    if(canNavigate){
      if(state.modif){
        allFunctions.createAnswersObject(false, navigate, null, dispatch, params)
      }
    navigate(link)
    }
  },
  
  getUnderMenu : (dispatch) => {
    const state = store.getState()
    if(state.allQuestions && state.actual && state.idMenu ) {
      dispatch(setUnderMenu(state.allQuestions[state.idMenu].pages))
    }
  },

  findFirstPage : (menuId) => {
    const state = store.getState()
    const page = state.allQuestions[menuId]
    return page
  },

  changeAnswer : (dispatch, params, type, answer, idQuestion) => {
    const {allQuestions}= store.getState()
    let payload = {}
    const pageIndex = allQuestions[params.menuId].pages.findIndex(obj => obj.text.id === parseInt(params.idPage))
    const questionIndex = allQuestions[params.menuId].pages[pageIndex].lots[params.indexLot].sousquestions.findIndex(obj => obj.id === parseInt(idQuestion))
    dispatch(setModif(true))
    switch (type) {
      case 'button':
        const answerObj = (Object.entries(answer)[0])
        payload['response'] = parseInt(answerObj[0])
        payload['textResponse'] = answerObj[1].text
        payload['value'] = answerObj[1].value
        break;
      case 'textarea' :
        payload['textResponse'] = answer
      break;
      case 'select' :
        payload = answer
      break;
      case 'slide' :
        payload = answer
      break;
      default:
        break;
    }
    if(payload && !payload.response) {
      payload['response'] = null 
    }
    if(payload && !payload.value && payload.value !== 0) {
      payload['value'] = null 
    }
    if(payload && !payload.textResponse && payload.textResponse !== 0) {
      payload['textResponse'] = null 
    }
    dispatch(setAllQuestionsAnswers(payload, params.menuId, pageIndex, params.indexLot, questionIndex, 'answer'))
    allFunctions.recursiveAnswers(dispatch, params.menuId, params.idPage, params.indexLot, idQuestion)
  },

  recursiveAnswers : (dispatch, menuId, idPage,indexLot, idQuestion, ) => {
    const {allQuestions}= store.getState()
    const page = allQuestions[menuId].pages.find(obj => obj.text.id === parseInt(idPage))
    const lot = page.lots[indexLot].sousquestions
    const question = lot.find(obj => obj.id === parseInt(idQuestion))

    if(question && question.othersQuestionsId && question.othersQuestionsId[0] ){
      question.othersQuestionsId.map(child => {
        const findChild = lot.find(obj => parseInt(obj.id) === parseInt(child))
      if(question.answer && question.answer.response){
              if(findChild.othersQuestionsId && findChild.othersQuestionsId[0]){
                allFunctions.recursiveAnswers(dispatch,menuId, idPage,indexLot , child)
              }
            if(findChild.indexAnswerForQuestion === parseInt(question.answer.response)){
              dispatch(setTableDisplay(true, child))
            } else {
              dispatch(setTableDisplay(false, child))
            }
          }
        })
      } 
      lot.forEach(question => {
        if(!question.isOtherQuestion){
          dispatch(setTableDisplay(true, question.id))
        }
      })
},



  createAnswersObject : (bool, navigate, docForBack, dispatch, params) => {
    const state = store.getState()
    const answers = {}
    for ( const [idTheme, theme] of Object.entries(state.allQuestions)) {
      const themeObj = {}
      theme.pages.map((page, pageIndex) => {
        const pageObj = {}
        page.lots.map((lot, lotIndex) => {
          lot.sousquestions.map((question, questionIndex) => {
            if(state.tableDisplay[question.id]){
              let answer = question.answer ? question.answer : {response: null, textResponse: null, value: null}
              pageObj[question.id] = answer
            } else {
              pageObj[question.id] = {response: null, textResponse: null, value: null}
            }
          })
        })
        themeObj[page.text.id] = pageObj
      })
      answers[idTheme] = themeObj
    }
    axiosFunc.sendResponses(bool, navigate, docForBack, answers, dispatch, params)
  },

  verifLast : (dispatch, params) => {
    const {generiquesTextes, userMenu, allQuestions} = store.getState()
    if (allQuestions && params && params.menuId){
      const menuIndex = Object.keys(allQuestions).findIndex(obj => obj === params.menuId)
      const findMenu = Object.entries(allQuestions).find(obj => obj[0] === params.menuId)
      const pageIndex = findMenu && findMenu[1] && findMenu[1].pages.findIndex(page => page.text.id === parseInt(params.idPage))
      if(menuIndex === userMenu.length -1){
          if(findMenu && findMenu[1] && pageIndex === findMenu[1].pages.length-1){
              allFunctions.createNotification('info', generiquesTextes.survey.validate)
              dispatch(setIsLast(true))
          } else {
              dispatch(setIsLast(false))
          }
      } else {
          dispatch(setIsLast(false))
      }
  }

    }
}

export default allFunctions