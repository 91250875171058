import { Card } from 'react-bootstrap'
import ReponseButton from '../Réponse/button';
import { useState, useEffect } from 'react';
import ReponseSelect from '../Réponse/select';
import ReponseSlide from '../Réponse/slide';
import ReponseTextarea from '../Réponse/textarea';
import { connect, useDispatch } from 'react-redux';
import uploadIco from '../../../assets/img/uIco.png'
import {setModif} from '../../../actions/action';
import MultipleChoice from '../Réponse/multipleChoice';

const Question = ( {sousquestion, docForBack, setDocForBack} ) => {
    const dispatch = useDispatch()
    const [infoBulle, setInfoBulle] = useState(false);

    const sendToAnswersForBack = (event) => {
        setDocForBack(prevState => {        
            const updatedState = {
                  doc: event.target.files[0], 
                }
                if (prevState === null || typeof prevState === 'undefined') {
                    // Créer un nouvel objet s'il n'existe pas encore
                    return {
                        [sousquestion.id]: updatedState,
                      }
                  } else {
                    // Remplacer l'objet existant par le nouvel objet
                    return {
                      ...prevState,
                        [sousquestion.id]: updatedState,
                      }
                    };
                  })
                };

const optionsValues = sousquestion.options ? Object.values(sousquestion.options) : []
const optionsKeys = sousquestion.options ? Object.keys(sousquestion.options) : []
const options = [];

useEffect(() => {
    if(optionsValues && sousquestion.askTypes === 'select'){
        const items = optionsValues.map((option, optionIndex) => ({
            label: option.text, value: option.value, index: optionIndex,
        }))
        options.push(...items)
    }
}, [optionsValues])

    return (
        <div className={sousquestion.isWide ? 'questions__card__wide' : 'questions__card__part'}>
            <div className={sousquestion.isWide ? 'questions__card__response__wide' : 'questions__card__response'} onMouseLeave={() => {setInfoBulle(false)}}>
                <Card.Text className={sousquestion.isWide ? 'questions__card__text__wide' : 'questions__card__text'}>{sousquestion.required ? '*' : null} {sousquestion.asks} 
                {sousquestion.infobulle != null ? 
                    <span className='questions__card__text__informations' onMouseEnter={() => {setInfoBulle(true)}} >?</span>
                : null }
                </Card.Text>
                {infoBulle ?
                <div className='infobulle'>
                    {sousquestion.infobulle.title}
                </div> : null}
            </div>
            <div className='questions__card__response'>
            {sousquestion.askTypes === 'button' ? 
                    optionsValues.map((option, optionIndex) => {
                    return (
                            <ReponseButton optionsKeys={optionsKeys} sousquestion={sousquestion} option={option} optionIndex={optionIndex} /> 
                    )
                    })
            : sousquestion.askTypes === 'select' ? 
                <ReponseSelect options={options} optionsKeys={optionsKeys} sousquestion={sousquestion} />
            : sousquestion.askTypes === 'slide' ?
                <ReponseSlide sousquestion={sousquestion} /> 
            : sousquestion.askTypes === 'textarea' ?
                <ReponseTextarea sousquestion={sousquestion} />
            : sousquestion.askTypes === 'multiple-choice' ?
                <MultipleChoice sousquestion={sousquestion} />
            : null 
            }
            {sousquestion.acceptFiles ?
            <form method="post" enctype="multipart/form-data" className='file'>
                <label><img src={uploadIco} className='icone' alt='upload' />
                    <input type='file' id='file_upload' name='file_upload' style={{ display: 'none' }} onChange={(event) => {sendToAnswersForBack(event); dispatch(setModif(true))}} accept= '.jpg, .jpeg, .png, .pdf, .bmp'/> 
                </label>
                <div className='file__name'>{docForBack && docForBack[sousquestion.id] && docForBack[sousquestion.id].doc.name ? docForBack[sousquestion.id].doc.name : null} </div>
            </form>
            
            : null
        }
        </div>
        </div>
        // : null
    )
}
                
 const states = (state) => ({
    languageSelected: state.languageSelected,
    actual: state.actual,
    modif: state.modif,
    idMenu: state.idMenu, });
  


export default connect(states)(Question);