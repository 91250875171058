import React, { useEffect } from 'react';
import { Text, View, Link } from '@react-pdf/renderer';


function extractTextAndLink(markdownText) {
    const regex = /\[([^\]]+)\]\(([^)]+)\)/;
    const match = markdownText.match(regex);

    if (match) {
      const textBeforeLink = markdownText.substring(0, match.index);
      const linkText = match[1];
      const linkUrl = match[2];
      const textAfterLink = markdownText.substring(match.index + match[0].length);
      
      return { textBeforeLink, linkText, linkUrl, textAfterLink };
    }
    return { textBeforeLink: markdownText, linkText: null, linkUrl: null, textAfterLink: null };
  }
  
  const NewMarkdown = ({ markdownText }) => {
    const { textBeforeLink, linkText, linkUrl, textAfterLink } = extractTextAndLink(markdownText);
    let newAfterLink = ''

    const regex = /\[([^\]]+)\]\(([^)]+)\)/;
    if(textAfterLink){
        const match = textAfterLink.match(regex);
        
        if(match) {
            newAfterLink = extractTextAndLink(textAfterLink)
        }
    }


    return (
      <View>
        {linkText && linkUrl ? 
        <Text>{textBeforeLink} <Link src={linkUrl}>{linkText}</Link> {newAfterLink ? <Text>{newAfterLink.textBeforeLink} <Link src={newAfterLink.linkUrl}>{newAfterLink.linkText}</Link> {newAfterLink.textAfterLink}</Text> : textAfterLink}</Text>
        : <Text>{textBeforeLink}</Text>}
      </View>
    );
  };
  
  const MarkdownText = ({ markdownText }) => {
     return (
        <NewMarkdown markdownText={markdownText} />
     )
  };

export default MarkdownText;
