import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import allFunctions from "../../Fonctions/functions";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";


const ReponseSelect = ({ options, optionsKeys, sousquestion }) => {
    const dispatch = useDispatch()
    const params = useParams()
    
    const selectValue = (event) => {
        let payload = {}
        payload['response'] = parseInt(optionsKeys[event.index])
        payload['value'] = event.value
        payload['textResponse'] = event.label
        allFunctions.changeAnswer(dispatch, params, sousquestion.askTypes, payload, sousquestion.id)
        if(sousquestion.identityType === 'role') {
            if(event.label === 'Dirigeant'){
                console.log('A FAIRE')
            }
        }
    }
      
    return (
     <Select options={options} className='questions__card__select' classNamePrefix='questions__card__select' unstyled onChange={(event) => selectValue(event)}
        defaultValue={sousquestion.answer && sousquestion.answer.textResponse ? {label: sousquestion.answer.textResponse} : null}
        />
        )   
    }
    
    
    const mapStates = (state) => ({

         });
      
    
    
    export default connect(mapStates)(ReponseSelect);