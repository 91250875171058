import { useNavigate } from "react-router-dom"
import { connect, useDispatch } from "react-redux";
import { useState } from "react";
import { setDisplayChangeAnswers } from "../../actions/action";

const AlertModal = ( {paramsQuestionnaire, idUser, action, alertContainer, button}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [displayModalConfirm, setDisplayModalConfirm] = useState();
    return (
    <div className='pin__modal'>
        <div className='pin__container'>
            {alertContainer && alertContainer.warning ? alertContainer.warning.title : 'Mise en garde'}
            <div className='pin__text'>
                {alertContainer && alertContainer.warning ? alertContainer.warning.text : 'Êtes vous sûr de vouloir modifier vos réponses ? Si vous modifier des réponses, vous écraserez les précédentes '}
            </div>
            <div className='home__button__container'>
                <div className='home__button__change' onClick={() => {setDisplayModalConfirm(true)}}>{button && button.yes ? button.yes : 'Oui'}</div>
                <div className='home__button__change' onClick={() => {dispatch(setDisplayChangeAnswers(false))}}>{button && button.no ? button.no : 'Non'}</div>
                { alertContainer ? <div className='home__button__change' onClick={() => {navigate(`${process.env.REACT_APP_FRONT_LINK}/rapport/${paramsQuestionnaire.id}/${idUser}`); dispatch(setDisplayChangeAnswers(false))}}>{alertContainer.accessReport}</div> : null}
            </div>
        </div>
        {displayModalConfirm ? 
        <div className="modal__background">
            <div className='modal__container'>
                <div className='modal__text'>
                    {alertContainer.warning.confirm.text}
                </div>

            <div className='home__button__container'>
                <div className='home__button__change' onClick={action}>{button.yes}</div>
                <div className='home__button__change' onClick={() => {setDisplayModalConfirm(false)}}>{button.no}</div>
            </div>
            </div>
        </div> 
        : null}
    </div>
    )}

const states = (state) => ({
    paramsQuestionnaire: state.paramsQuestionnaire,
    idUser: state.idUser,
    alertContainer: state.generiquesTextes.unlockSurvey,
    button: state.generiquesTextes.button,
});
  
  
  export default connect(states)(AlertModal);
