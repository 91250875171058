import { useState } from "react"
import { Checkbox } from "@mui/material"
import { connect } from "react-redux"
const Recap = ( {header, data, totalUser, totalLabelUser, totalAdmin}) => {
    const [displayTable, setDisplayTable] = useState(true)
    const [allPoints, setAllPoints] = useState(false)    

    return (
        <div className='recap__table'>
            <div className='recap__table__column__right'>
                Intégrer les questions sans Score Label Minimum
                <Checkbox checked={allPoints} onChange={() => {setAllPoints(!allPoints)}}/>
            </div>

            <table className='datatable'>
                <thead className='datatable__header'>
                    <tr className='datatable__row__title'>
                        <th className='datatable__title'>
                                {displayTable ? 
                                    <div className='datatable__flex' onClick={() => setDisplayTable(!displayTable)}> <div className='datatable__arrow reverse'/></div>: 
                                    <div className='datatable__flex' onClick={() => setDisplayTable(!displayTable)}> <div className='datatable__arrow'/> </div> }
                        </th>
                    {header && header.map((head, headIndex) => (
                            <th className='datatable__title'>{head.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data && data.map((d, dIndex) => (
                        displayTable ?
                        !allPoints ?
                            d.scoreAdmin > 0 ? 
                                <tr className='datatable__row'>
                                    <td className='datatable__index'>{dIndex+1}</td>
                                    <td className='datatable__cellule'>{d.title}</td>
                                    <td className='datatable__score'>{d.scoreLabelUser ? d.scoreLabelUser : d.scoreUser}</td>
                                    <td className='datatable__score'>{d.scoreAdmin}</td>
                                </tr>
                            : null
                        :
                        <tr className='datatable__row'>
                            <td className='datatable__index'>{dIndex+1}</td>
                            <td className='datatable__cellule'>{d.title}</td>
                            <td className='datatable__score'>{d.scoreUser}</td>
                            <td className='datatable__score'>{d.scoreAdmin}</td>
                        </tr>
                        : null
                    ))}
                </tbody>
                <tfoot className="datatable__footer">
                    <tr className="datatable__footer__row">
                        <td className="datatable__footer__title">
                            Total
                        </td>
                        <td>

                        </td>
                        <td className="datatable__footer__score">
                            {allPoints ? totalLabelUser : totalUser}
                        </td>
                        <td className="datatable__footer__score">
                            {totalAdmin}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

const mapState = (state) => ({

})

export default connect(mapState)(Recap)