import { useNavigate, useParams } from 'react-router-dom';
import { setIdUser} from '../../actions/action.js';
import { useDispatch, connect } from 'react-redux';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import axiosFunc from '../Fonctions/axios.js';
import allFunctions from '../Fonctions/functions.js';

const AppWrapper = ({allQuestions, languageSelected, languageDefault, paramsQuestionnaire, generiqueTextes, actual}) => {
  const params = useParams();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    dispatch(setIdUser(params.idUser))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]) 

  useEffect(() => {
  function getAllParams() {
    (async () => {
        try {
          if (!generiqueTextes) {
            await axiosFunc.getTextes(dispatch);
          }
          if (!paramsQuestionnaire) {
           const response = await axiosFunc.getParamsQuestionnaire(params.questionnaire, dispatch);
           axiosFunc.getHomePage(response.data.id, languageSelected, languageDefault, dispatch);
          }
          
        } catch (error) {
          console.error('Une erreur est apparu', error);
        }
      })()
  }
    if (Cookies.get('token')) {
      getAllParams(Cookies.get('token'))
    } else {
    (async() => {
      await axiosFunc.getToken(dispatch, allQuestions, navigate, params.questionnaire)
      getAllParams()
    })()

    }
  }, [params.questionnaire, Cookies.get('token')])

  useEffect(() => {
    allFunctions.verifLast(dispatch, params)
  }, [params.idQuestion])

  useEffect(() => {
    async function fetchData() {
      await allFunctions.setInfosWeb(dispatch, params)
      .then(() => {
        allFunctions.setActualQuestionnaire(dispatch, params)
        allFunctions.setNextQuestion(dispatch, params)
      })
      .catch((error) => { console.log(error)})
    } 
    fetchData()
    allFunctions.verifLast(dispatch)
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [params.idQuestion, allQuestions])


}
const states = (state) => ({
  allQuestions: state.allQuestions,
  languageDefault: state.languageDefault,
  languageSelected: state.languageSelected,
  paramsQuestionnaire: state.paramsQuestionnaire,
  actual: state.actual,
  generiqueTextes: state.generiqueTextes,
});
  
export default connect(states)(AppWrapper);