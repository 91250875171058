import { connect, useDispatch } from "react-redux"
import { useState } from "react"
import './menuchoice.scss'
import {setChangeUserMenu } from "../../actions/action"
import { Puff  } from "react-loader-spinner"
import Modal from "../Modal"
import Checkbox from '@mui/material/Checkbox';
import TextComponent from "../Page/TextComponent"
import axiosFunc from "../Fonctions/axios"
import { useNavigate } from "react-router-dom"

const MenuChoice = ( {themes, generiquesTextes, userThemes}) => {

    const dispatch = useDispatch()
    const [displayModal, setDisplayModal] = useState(false)
    const navigate = useNavigate()


    const handleOptionClick = (menu) => {
        dispatch(setChangeUserMenu(menu))
      };
          
    return (
        userThemes && userThemes[0] && themes && themes[0] ?
        <div className='menuChoice'>
            <div className='menuChoice__intro'> {generiquesTextes.selectTheme.text}</div>
            <div className="menuChoice__themes__container">
            {themes.map((theme, themeIndex) => {
              
               const findSelectedActual = userThemes.find((obj) => obj.id === theme.id)
               return(
               <div>
                 <div className={themeIndex === themes.length-1 ? 'menuChoice__themes menuChoice__themes__last' : 'menuChoice__themes'}>
                   <div  className={findSelectedActual ? "menuChoice__themes__text menuChoice__themes__text__animated" : "menuChoice__themes__text"}>
                       {theme.title}
                   </div>
                   <div className="menuChoice__themes__text">
                     <TextComponent text={theme.description} />
                   </div>
                   <div className="menuChoice__themes__text" > 
                     {theme.necessaryChapter ? <Checkbox checked={true} disabled /> : <Checkbox checked={findSelectedActual ? findSelectedActual : false} onClick={() => {handleOptionClick(theme)}} />}
                     </div>
                 </div>
                 {themeIndex !== themes.length-1 ? <div className="separator" /> : null}
                 </div>
               )
           })}  
           </div>          
           <div 
           className='menuChoice__button menuChoice__button__validate'
           onClick={() => {axiosFunc.sendSelectedMenu(false, dispatch, setDisplayModal, navigate)}}>
               Valider {userThemes.length > 1 ? 'vos choix' : 'votre choix'}
           </div> 
           
           


   {displayModal ? <Modal setDisplayModal={setDisplayModal} action={() => axiosFunc.sendSelectedMenu(true, dispatch, setDisplayModal, navigate)} boutonOK={generiquesTextes.button.validate} boutonStop={generiquesTextes.button.cancel} text={generiquesTextes.selectTheme.warning} /> : null}

        </div>
        :   <div className="loader">
            <Puff 
                visible={true}
                color="rgb(218,91,21)"
                height=""
                width=""
                />
              </div>
    )
}


const mapStateToProps = (state) => ({
    themes: state.menu,
    userThemes : state.userMenu,
    selectedThemes: state.selectedThemes,
    languageDefault: state.languageDefault,
    languageSelected: state.languageSelected,
    idCorrespond: state.idCorrespond,
    idQuestionnaire: state.paramsQuestionnaire.id,
    generiquesTextes: state.generiquesTextes,
    allQuestions: state.allQuestions,
});
  const mapDispatchToProps = {
    };
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(MenuChoice);