import { PinField } from "react-pin-field";

const ModalPin = ( {textModal, setCodePin, setDisplayModalPin}) => {
    return (
        <div className='modal__pin__background'>
            <div className='modal__pin'>
                {textModal} 
                <div className='modal__pinfield__container'>
            <PinField 
                length={4}
                validate={/^[0-9]$/} 
                onChange={(event) => {setCodePin(event)}}
                type= {'password'}
                className="modal__pinfield" />
                <div className='modal__pin__buttonClose' onClick={() => {setDisplayModalPin(false)}}></div>
            </div>
            </div>
            </div>
    )
}

export default ModalPin
