import { connect, useDispatch } from 'react-redux'
import { useState} from 'react'
import { setLanguage } from '../actions/action'
import allFunctions from './Fonctions/functions'
import { useNavigate } from 'react-router-dom'

const Footer = ({allLanguages, modif, generiquesText, isLast}) => {
    const [displayBack, setDisplayBack] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    return (
        <div className='footer'>
            <div className='footer__grid'>
                <div className='footer__text__container'>
                    <p className='footer__text' onClick={() => {setDisplayBack(!displayBack)}}>{allLanguages[0] && allLanguages[0].nom ? allLanguages[0].nom : null}</p>
                </div>
                <div className={displayBack ? 'footer__back' : 'footer__back footer__back__close'}>
                    {allLanguages.map((langue, langueIndex) => langueIndex !== 0 ? (
                        <p className='footer__language' key={langueIndex} onClick={() => {dispatch(setLanguage(langue.code))}}>{langue.nom ? langue.nom : null}</p>
                    ) : null )}
                </div>
            </div>
            <div className='footer__grid'>
                <p className='footer__company'>Powered By URANIE</p>
            </div>
            <div className='footer__grid'>
                    {isLast ?
                    <div className='footer__button' onClick={() => {allFunctions.createAnswersObject(true, navigate, null, dispatch); }}>
                        {generiquesText && generiquesText.button && generiquesText.button.validate}
                    </div> 
                    : modif ? 
                    <div className='footer__button' onClick={() => {allFunctions.createAnswersObject(false, navigate, null, dispatch); }}>
                        {generiquesText && generiquesText.button && generiquesText.button.save}
                    </div> 
                    : null}
            </div>
        </div>
    )
}

const states = (state) => ({
    allLanguages : state.languages,
    modif: state.modif,
    generiquesText: state.generiquesTextes,
    isLast: state.isLast,
});

export default connect(states)(Footer)