import { connect, useDispatch } from "react-redux";
import allFunctions from "../../Fonctions/functions";
import { useParams } from 'react-router-dom'
import { useEffect, useState } from "react";

const ReponseButton = ({optionsKeys, sousquestion,option, optionIndex, actual}) => {
    const dispatch = useDispatch();
    const params = useParams()
    const [actualQuestion,setActualQuestion] = useState({})

    useEffect(() => {
      setActualQuestion(actual.lot.find(obj => obj.id === sousquestion.id))
    }, [actual])

    return (
        <button key={optionIndex} 
          className={
            actualQuestion && actualQuestion.answer &&
            parseInt(actualQuestion.answer.response) === parseInt(optionsKeys[optionIndex])
          ? 'questions__card__button other__choice' : 'questions__card__button'} 
          onClick={() => {allFunctions.changeAnswer(dispatch,params, 'button', {[optionsKeys[optionIndex]]: option}, sousquestion.id)}}>
          {option.text}
        </button>
    )
}

const states = (state) => ({
    idUser: state.idUser,
    idMenu: state.idMenu,
    actual : state.actual,
     });
  


export default connect(states)(ReponseButton); 