import fond from '../assets/img/valliance/bas_de_page.png'

/* Dans questions :
categorys = au menu sélectionné, affiche les questions en fonction de la catégorie choisie;
pages     = différencie les différentes pages de questions;
groups    = les différents groupes de questions (un group = une card)
lots      = toutes les questions dans chaque groupe
*/

const initialState = {
  path: '',
  languages: [],
  allQuestions: null,
  languageSelected: 'FR',
  languageDefault: 'EN',
  menu: {},
  userMenu: [],
  errorPin: false,
  underMenu: {},
  menuSelect: 0,
  questions : [],
  idUser: null,
  idMenu: null,
  idPage: null,
  idChapitre: null,
  indexLot: null, 
  idQuestion: null,
  needRapport: true,
  firstname: '',
  lastname: '',
  dirigeantlastname: '',
  dirigeantfirstname: '',
  dirigeantmail: '',
  fonction: false,
  modif: false,
  token: false,
  homepage: null,
  themes: {},
  selectedThemes: [],
  idCorrespond: [],
  displayUnderMenu: true,
  rapport: {},
  displayReportMenu: false,
  themeSelectionActive: false,
  summaryScore: {},
  tableDisplay: [],
  loader: false,
  profilForEnd: {},
  backgroundApp : {
    backgroundImage: `url(${fond})`,
    backgroundSize: 'cover',
    backgroundPosition: 'right bottom calc(10% - 25vh)',
    backgroundRepeat: 'no-repeat',
  },
  alertContainer: false,
  actual: {},
  next: {},
  modalPin: false,
  forgotPin: false, 
  generiquesTextes: {},
  paramsQuestionnaire : null,
  userMail : null,
  color : {
    darkBlue: 'rgb(0,129,183)',
    blue: 'rgb(0,174,217)',
    middleBlue: 'rgb(77, 219, 255)',
    darkGrey: 'rgb(88,88,90)',
    grey: 'rgb(135,136,138)',
    middleGrey: 'rgb(175,175,175)',
    darkOrange: 'rgb(218,91,21)',
    orange: 'rgb(244,157,0)',
    middleOrange: 'rgb(254, 204, 109)',

    majorColor : 'rgb(0,129,183)',
    secondaryColor : 'rgb(218,91,21)',
    lightMajorColor: 'rgb(0,174,217)',
    lightSecondaryColor: 'rgb(244,157,0)',
  },
  displayChangeAnswers: false,
  isLast: false,
}

function reducer(state = initialState, action){
  switch (action.type){
    case 'SET_MENU': return{...state, menu : action.payload};
    case 'SET_MENU_USER': return{...state, userMenu : action.payload};
    case 'SET_MENU_SELECT': return{...state, menuSelect : action.payload};
    case 'SET_PATH': return{...state, path: action.payload};
    case 'SET_STEP': return{...state, step: action.payload};
    case 'SET_LANGUAGES': return{...state, languages: action.payload};
    case 'SET_LANGUAGE_SELECTED': return{...state, languageSelected: action.payload};
    case 'SET_ALL_QUESTIONS': return{...state, allQuestions: action.payload};
    case 'SET_DISPLAY_CHANGE_ANSWERS': return{...state, displayChangeAnswers: action.payload};
    case 'SET_ERROR_PIN': return{...state, errorPin: action.payload}
    case 'SET_IS_LAST' : return{...state, isLast: action.payload}
    case 'SET_CHANGE_USER_MENU':  
    const menuExists = state.userMenu.some(menu => menu.id === action.payload.id);
    return {
      ...state,
      userMenu: menuExists
        ? state.userMenu.filter(menu => menu.id !== action.payload.id)
        : [...state.userMenu, action.payload],
    };
    case 'SET_ALL_QUESTIONS_ANSWERS' : 
    return {
      ...state,
      allQuestions: {
        ...state.allQuestions,
        [action.idMenu]: {
          ...state.allQuestions[action.idMenu],
          pages: state.allQuestions[action.idMenu].pages.map((page, pIndex) =>
            pIndex === parseInt(action.pageIndex)
              ? {
                  ...page,
                  lots: page.lots.map((lot, lIndex) =>
                    lIndex === parseInt(action.lotIndex)
                      ? {
                          ...lot,
                          sousquestions: lot.sousquestions.map((question, qIndex) => {
                            return (
                            qIndex === parseInt(action.questionIndex)
                            ? {
                              ...question,
                              [action.key]: action.payload,
                            }
                            : question
                  )}),
                        }
                      : lot
                  ),
                }
              : page
          ),
        },
      },
    };
    case 'SET_ID_USER': return{...state, idUser: action.payload};
    case 'SET_ID_MENU': return{...state, idMenu: action.payload};
    case 'SET_ID_PAGE': return{...state, idPage: action.payload};
    case 'SET_INDEX_LOT': return{...state, indexLot: action.payload};
    case 'SET_ID_QUESTION': return{...state, idQuestion: action.payload};
    case 'SET_NEED_RAPPORT': return{...state, needRapport: action.payload}
    case 'SET_RAPPORT': return{...state, rapport: action.payload}
    case 'SET_FIRSTNAME': return{...state, firstname: action.payload}
    case 'SET_DIRIGEANT_FIRSTNAME': return{...state, dirigeantfirstname: action.payload}
    case 'SET_LASTNAME': return{...state, lastname: action.payload}
    case 'SET_DIRIGEANT_LASTNAME': return{...state, dirigeantlastname: action.payload}
    case 'SET_DIRIGEANT_MAIL': return{...state, dirigeantmail: action.payload}
    case 'SET_FONCTION': return{...state, fonction: action.payload}
    case 'SET_MODIF': return{...state, modif: action.payload}
    case 'SET_TOKEN': return{...state, token: action.payload}
    case 'SET_HOMEPAGE': return{...state,homepage: action.payload}
    case 'SET_THEMES': return{...state, themes: action.payload}
    case 'SET_SELECTED_THEMES': return{...state, selectedThemes: action.payload}
    case 'SET_DISPLAY_UNDERMENU' : return{...state, displayUnderMenu: action.payload}
    case 'SET_ID_CHAPITRE': return{...state, idChapitre: action.payload}
    case 'SET_ID_CORRESPOND': return{...state, idCorrespond: action.payload}
    case 'SET_DISPLAY_REPORT_MENU': return{...state, displayReportMenu: action.payload}
    case 'SET_THEME_SELECTION_ACTIVE': return{...state, themeSelectionActive: action.payload}
    case 'SET_SUMMARY_SCORE': return{...state, summaryScore: action.payload}
    case 'SET_TABLE_DISPLAY': return{...state, tableDisplay: {...state.tableDisplay, [action.id] : action.payload}}
    case 'SET_LOADER': return{...state, loader: action.payload}
    case 'SET_PROFIL_FOR_END': return{...state, profilForEnd: action.payload}
    case 'SET_ALERT_CONTAINER': return{...state, alertContainer: action.payload}
    case `SET_ACTUAL_${action.case}`: return{...state, actual : {...state.actual, [action.key] : action.payload}}
    case `SET_ACTUAL_LOT_SPE` : return {...state, actual : {...state.actual, [action.index] : {...state.actual[action.index], [action.key] : action.payload}}}
    case `SET_NEXT_${action.case}`: return{...state, next : {...state.next, [action.key] : action.payload}}
    case 'SET_MODAL_PIN' : return{...state, modalPin: action.payload}
    case 'SET_FORGOT_PIN' : return{...state, forgotPin: action.payload}
    case 'SET_GENERIQUES_TEXTES' : return{...state, generiquesTextes : action.payload}
    case 'SET_PARAMS_QUESTIONNAIRE' : return{...state, paramsQuestionnaire : action.payload}
    case 'SET_USER_MAIL' : return {...state, userMail : action.payload}
    case 'SET_USER_MENU' : return {...state, userMenu : action.payload}
    
    case `SET_ALL_LEVEL` :        return {...state, [action.name] : action.payload}
    case `SET_ALL_LEVEL_1` :      return {...state, [action.name] : 
                                          {...state[action.name], [action.first] : action.payload}}
    case `SET_ALL_LEVEL_1_2` :    return {...state, [action.name] : 
                                          {...state[action.name], [action.first] : 
                                            {...state[action.name][action.first], [action.second] : action.payload}}}
    case `SET_ALL_LEVEL_1_2_3` :  return {...state, [action.name] : 
                                          {...state[action.name], [action.first] : 
                                            {...state[action.name][action.first], [action.second] : 
                                              {...state[action.name][action.first][action.second], [action.key] : action.payload}}}}
    case `SET_ALL_LEVEL_1_2_3_4`: return {...state, [action.name] : 
                                          {...state[action.name], [action.first] : 
                                            {...state[action.name][action.first], [action.second] : 
                                              {...state[action.name][action.first][action.second], [action.third] : 
                                                {...state[action.name][action.first][action.second][action.third], [action.key] : action.payload}}}}}
    case `SET_SIGNUPINFOS_${action.cle1}_${action.cle2}`: return {...state, signUpInfos : {...state.signUpInfos, [action.cle1] : {...state.signUpInfos[action.cle1], [action.cle2] : action.payload}}}
    case 'SET_UNDERMENU' : return{...state, underMenu : action.payload}
      default: return state;
}
}

export default reducer;