import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { connect } from 'react-redux';

const GaugGraph = ( {data, dataBack, color}) => {
  const chartRef = useRef(null);
  let option

  useEffect(() => {
    const myChart = echarts.init(chartRef.current);
    const gaugeData = data
      option = {
        color: [
          {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 0, color: 'rgb(88,88,90)'
          }, {
            offset: 1, color: 'rgb(135,136,138)'
          }],
        },
        {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 0, color: color.majorColor
          }, {
            offset: 1, color: color.lightMajorColor
          }],
        },
        dataBack[2] ?
        {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 0, color: 'rgb(0,129,183)'
          }, {
            offset: 1, color: 'rgb(0,174,217)'
          }],
          
        } : null],
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -280,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: 'white'
              }
            },
            axisLine: {
              lineStyle: {
                width: 40
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: gaugeData,
            title: {
              fontSize: 14
            },
            detail: {
              width: 50,
              height: 14,
              fontSize: 14,
              color: 'inherit',
              borderColor: 'inherit',
              borderRadius: 20,
              borderWidth: 1,
              formatter: '{value}%'
            }
          }
        ]
      };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [data]);

  return <div ref={chartRef} style={{ width: '100%', height: '50vh' }}></div>;
};

const mapState = (state) => ({
  color : state.color
})

export default connect(mapState)(GaugGraph) ;