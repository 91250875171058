import allFunctions from '../Fonctions/functions';
import './modal.scss'

const Modal = ({text, boutonOK, boutonStop, setDisplayModal, action}) => {

    const handleActionClick = async (bool) => {
        if(bool){
            try {
              await action(bool);
            setDisplayModal(false);
            } catch (error) {
              allFunctions.createNotification('error', 'Erreur d\'enregistrement, contactez votre administrateur', 'Erreur')
            }
        } else {
            setDisplayModal(false)
        }
      };
    return (
        <div className='modal__background'>
            <div className='modal__container'>
                <div className='modal__text'>
                    {text}
                </div>
                <div className='modal__button__container'>
                    <div className='modal__button' onClick={() => handleActionClick(true)}>{boutonOK}</div>
                    <div className='modal__button' onClick={() => handleActionClick(false)}>{boutonStop}</div>
                </div>
            </div>
        </div>
    )
}

export default Modal